import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, CardTitle, Button, FormFeedback, Form } from "reactstrap";
import '../../assets/scss/style.css'
// import Dropzone from "react-dropzone"

import { withTranslation } from "react-i18next";
import { baseUrl } from "config"
import axios from "axios"
import * as Yup from "yup"
import { useFormik } from "formik"

const NFTImage7 = 'https://dubai-attraction.com/wp-content/uploads/2021/12/ain-dubai-observation-wheel-550x550.jpg';

const ShowNFT = props => {

    const history = useHistory();
    let auth = JSON.parse(localStorage.getItem("authUser"))
    document.title = "Create Feature";
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [form, setForm] = useState({ name: '' });

    const changeValues = event => {
        setForm({ ...form, ['name']: event.target.value })
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: form.name,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("This field is required."),
        }),
        onSubmit: values => {
            createFeature(values)
        },
    })

    const createFeature = async data => {

        axios
            .post(baseUrl + "package/save-feature", {
                name: data.name,
            }, {
                headers: { Authorization: `${auth.accessToken}` },
            })
            .then(res => {

                console.log(res, 'res feature >>>>>>>>>>>>>>>')
                history.push("/admin/features")
            })
            .catch(error => {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: error?.response?.data?.error,
                    showConfirmButton: false,
                    timer: 2000,
                })
            })
    }



    return (
        <React.Fragment>
            <div className="page-content margin-custom">
                <Container fluid>

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <Form
                                        className="needs-validation create-vendor"
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                    >
                                        <CardTitle className="h5 mb-4">Create Feature</CardTitle>

                                        <Row className="">
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <label htmlFor="floatingnameInput">Name</label>
                                                    <input type="text" className="form-control" id="floatingnameInput" placeholder="Writer here..."
                                                        onBlur={validation.handleBlur}
                                                        onChange={changeValues}
                                                        value={form.name} />

                                                    {validation.touched.name && validation.errors.name ? (
                                                        <FormFeedback type="invalid"> {validation.errors.name} </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="mb-4     ">
                                            <Link to="/admin/features" className="btn btn-secondary w-md mt-2 me-2">
                                                Cancel
                                            </Link>
                                            <button type="submit" className="btn btn-primary w-md mt-2">Submit</button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};


ShowNFT.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ShowNFT);
