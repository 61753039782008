import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Table } from "reactstrap";
import { Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import '../../assets/scss/style.css'
import classnames from "classnames"

import { withTranslation } from "react-i18next";

const NFTImage7 = 'https://bluewatersdubai.ae/-/media/Project/MeraasEcosystem/Bluewater/Whats-On/droneshow/droneshow.jpg';

import img1 from "../../assets/images/users/avatar-1.jpg";
import img2 from "../../assets/images/users/avatar-2.jpg";
import img3 from "../../assets/images/users/avatar-3.jpg";
import img4 from "../../assets/images/users/avatar-4.jpg";
import img5 from "../../assets/images/users/avatar-5.jpg";
import img6 from "../../assets/images/users/avatar-6.jpg";
import img7 from "../../assets/images/users/avatar-7.jpg";
import img8 from "../../assets/images/users/avatar-8.jpg";

const NFTImage1 = 'https://upload.wikimedia.org/wikipedia/commons/e/e7/Ferris_Wheel_Ain_Dubai_in_Dubai_02.jpg';
const NFTImage2 = 'https://dubaieyewheel.com/wp-content/uploads/2022/01/WhatsApp-Image-2022-01-13-at-12.02.43-AM-300x300.jpeg';
const NFTImage3 = 'https://www.traveller.com.au/content/dam/images/h/2/1/0/x/2/image.related.socialLead.620x349.h210wq.png/1642123242787.jpg';
const NFTImage4 = 'https://images.prismic.io/mystique/54b948bc-e603-47cc-a2b7-48085cb1bf0e_ain+dubai+1.jpg?auto=compress%2Cformat&w=1200&h=450&q=75&crop=faces&fm=webp&rect=0,0,797,498';
const NFTImage5 = 'https://media.npr.org/assets/img/2021/10/26/gettyimages-1194524615_sq-972b7802bd5a897861640c76900c982e71cbc818.jpg';
const NFTImage6 = 'https://media.npr.org/assets/img/2021/10/26/gettyimages-1194524615_sq-972b7802bd5a897861640c76900c982e71cbc818.jpg';
const NFTImage8 = 'https://cdn.pixabay.com/photo/2020/12/13/23/18/ferris-wheel-5829622_1280.jpg';


const ShowNFT = props => {
    const settings = {
        arrows: true,
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    //meta title
    document.title = "NFT's Details";
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    return (
        <React.Fragment>
            <div className="page-content margin-custom">
                <Container fluid>
                    <Row>
                        <Col xl="3" className="p-2 border">
                            <img src={NFTImage7} style={{ width: '100%', height: '100%' }} />
                        </Col>

                        <Col xl="9">
                            <Card className="border mb-0">
                                <div className="p-4 mt-2">
                                    <h5 className="text-primary"> DSF Drone Show  </h5>
                                    <p className="mb-2 mt-2 text-muted">
                                        <i className="mdi mdi-google-maps text-info me-1"></i> Bluewaters Island, Dubai
                                        <i className="mdi mdi-calendar text-info me-1 ms-4"></i> 09 November, 2022
                                    </p>

                                    <p className="mt-4 mb-3">
                                        The stage is set for one of the world's biggest dance music sensations as he returns to the city for a spectacular showcase with an expected audience of over 10,000 fans. Head to the Coca-Cola Arena  to watch Dutch DJ Martin Garrix live on 30 December. </p>

                                    <p className="mt-2 mb-0">
                                        The award-winning artist who was ranked number one on DJ Mag's  Top 100 DJs list for three consecutive years has performed at renowned music festivals like Coachella, Electric Daisy Carnival, Ultra Music Festival, Tomorrowland and Creamfields. Hear the hitmaker spin chart-toppers like Animals, In The Name Of Love, Wizard and Scared To Be Lonely. In 2021 alone Garrix had a billion streams on Spotify for recent tracks including  in collaboration with Tove Lo. Book your tickets now for this concert that's sure to electrify. Doors open at 6pm. Electric Daisy Carnival, Ultra Music Festival, Tomorrowland and Creamfields. Hear the hitmaker spin chart-toppers like Animals, In The Name Of Love, Wizard and Scared To Be Lonely. Doors open at 6pm. Electric Daisy Carnival, Ultra Music Festival, Tomorrowland and Creamfields. Book your tickets now for this concert that's sure to electrify. Doors open at 6pm. Electric Daisy Carnival, Ultra Music Festival, Tomorrowland and Creamfields. </p>
                                </div>
                            </Card>
                        </Col>

                        <h4 className="card-title mb-3 mt-3"> Visited Users <span className="font-theme-color"> [356] </span> </h4>
                        <Col xl="12" className="border">
                            <Card className="my-2">

                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <Thead>
                                            <Tr>
                                                <Th>Sr. No.</Th>
                                                <Th>User</Th>
                                                <Th>NFT</Th>
                                                <Th>Date Time</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <tr>
                                                <td> <span className="co-name"> 1 </span> </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={img1}
                                                            className="avatar-sm me-3 rounded-circle"
                                                            alt="img"
                                                        />
                                                        <div className="">
                                                            <h5 className="font-size-14">Jared Smith </h5>
                                                            <p className="text-muted mb-0">jared.smith03@gmail.com</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={NFTImage1}
                                                                className="avatar-sm me-3 rounded-circle"
                                                                alt="img"
                                                            />
                                                            <div className="">
                                                                <h5 className="font-size-14 mb-1">The Caesars Palace </h5>
                                                                <p className="text-muted mb-0"># 1326</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                <td>14 Nov., 2022 01:00 AM</td>
                                            </tr>

                                            <tr>
                                                <td> <span className="co-name"> 2 </span> </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={img2}
                                                            className="avatar-sm me-3 rounded-circle"
                                                            alt="img"
                                                        />
                                                        <div className="">
                                                            <h5 className="font-size-14">Francis MacKinnon </h5>
                                                            <p className="text-muted mb-0">francis.macKinnon@gmail.com</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={NFTImage2}
                                                                className="avatar-sm me-3 rounded-circle"
                                                                alt="img"
                                                            />
                                                            <div className="">
                                                                <h5 className="font-size-14 mb-1">The Minting Space </h5>
                                                                <p className="text-muted mb-0"># 1632</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                <td> 21 Nov., 2022 01:00 AM</td>
                                            </tr>

                                            <tr>
                                                <td> <span className="co-name"> 3 </span> </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={img3}
                                                            className="avatar-sm me-3 rounded-circle"
                                                            alt="img"
                                                        />
                                                        <div className="">
                                                            <h5 className="font-size-14">Charles Studd </h5>
                                                            <p className="text-muted mb-0">charles.studd@gmail.com</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={NFTImage3}
                                                                className="avatar-sm me-3 rounded-circle"
                                                                alt="img"
                                                            />
                                                            <div className="">
                                                                <h5 className="font-size-14 mb-1">Beast Ape </h5>
                                                                <p className="text-muted mb-0"># 1426</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                <td>12 Nov., 2022 01:00 AM</td>
                                            </tr>

                                            <tr>
                                                <td> <span className="co-name"> 4 </span> </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={img4}
                                                            className="avatar-sm me-3 rounded-circle"
                                                            alt="img"
                                                        />
                                                        <div className="">
                                                            <h5 className="font-size-14">Sandford Schultz </h5>
                                                            <p className="text-muted mb-0">sandford.schultz@gmail.com</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={NFTImage4}
                                                                className="avatar-sm me-3 rounded-circle"
                                                                alt="img"
                                                            />
                                                            <div className="">
                                                                <h5 className="font-size-14 mb-1">The Bull </h5>
                                                                <p className="text-muted mb-0"># 1532</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                <td>11 Nov., 2022 01:00 AM</td>
                                            </tr>

                                            <tr>
                                                <td> <span className="co-name"> 5 </span> </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={img5}
                                                            className="avatar-sm me-3 rounded-circle"
                                                            alt="img"
                                                        />
                                                        <div className="">
                                                            <h5 className="font-size-14">Samson S. </h5>
                                                            <p className="text-muted mb-0">samson.s@gmail.com</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={NFTImage5}
                                                                className="avatar-sm me-3 rounded-circle"
                                                                alt="img"
                                                            />
                                                            <div className="">
                                                                <h5 className="font-size-14 mb-1">Contractions </h5>
                                                                <p className="text-muted mb-0"># 1632</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                <td>11 Nov., 2022 01:00 AM</td>
                                            </tr>

                                            <tr>
                                                <td> <span className="co-name"> 6 </span> </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={img6}
                                                            className="avatar-sm me-3 rounded-circle"
                                                            alt="img"
                                                        />
                                                        <div className="">
                                                            <h5 className="font-size-14">Tom Armitage </h5>
                                                            <p className="text-muted mb-0">tom.armitage@gmail.com</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src="https://lh5.googleusercontent.com/p/AF1QipPMgqUyrHU7q26haWc51wRH-wWxFw6kNILJ__mE=w408-h272-k-no"
                                                                className="avatar-sm me-3 rounded-circle"
                                                                alt="img"
                                                            />
                                                            <div className="">
                                                                <h5 className="font-size-14 mb-1">YOLO HOLIDAY </h5>
                                                                <p className="text-muted mb-0"># 1253</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                <td>09 Nov., 2022 01:00 AM</td>
                                            </tr>

                                            <tr>
                                                <td> <span className="co-name"> 7 </span> </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={img7}
                                                            className="avatar-sm me-3 rounded-circle"
                                                            alt="img"
                                                        />
                                                        <div className="">
                                                            <h5 className="font-size-14">Andrew Greenwood </h5>
                                                            <p className="text-muted mb-0">andrew.greenwood@gmail.com</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={NFTImage8}
                                                                className="avatar-sm me-3 rounded-circle"
                                                                alt="img"
                                                            />
                                                            <div className="">
                                                                <h5 className="font-size-14 mb-1">Nyolings </h5>
                                                                <p className="text-muted mb-0"># 1555</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                <td>09 Nov., 2022 01:00 AM</td>
                                            </tr>
                                        </Tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};


ShowNFT.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ShowNFT);
