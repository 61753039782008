import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, CardTitle, Button } from "reactstrap";
import '../../assets/scss/style.css'
import Select from "react-select";

import { withTranslation } from "react-i18next";

const NFTImage7 = 'https://dubai-attraction.com/wp-content/uploads/2021/12/ain-dubai-observation-wheel-550x550.jpg';

const ShowNFT = props => {

    //meta title
    document.title = "Event Create";
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [selectedMulti2, setselectedMulti2] = useState(null);

    function handleMulti2(selectedMulti2) {
        setselectedMulti2(selectedMulti2);
    }
    const optionGroup1 = [
        {
            options: [
                { label: "Virtual Worlds", value: "Virtual Worlds" },
                { label: "Trading Cards", value: "Trading Cards" },
                { label: "Collectibles", value: "Collectibles" },
                { label: "Photography", value: "Photography" },
                { label: "Sports", value: "Sports" },
                { label: "Music", value: "Music" },
                { label: "Utility", value: "Utility" },
            ]
        }
    ];

    return (
        <React.Fragment>
            <div className="page-content margin-custom">
                <Container fluid>

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h5 mb-4">Create Event</CardTitle>

                                    <Row className="">
                                        <Col md={6}>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <label htmlFor="floatingnameInput">Name</label>
                                                        <input type="text" className="form-control" id="floatingnameInput" placeholder="Writer here..." />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <label htmlFor="floatingnameInput">Select NFT's</label>
                                                    <div className="form-floating mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                                        <Select
                                                            value={selectedMulti2}
                                                            isMulti={true}
                                                            onChange={() => {
                                                                handleMulti2();
                                                            }}
                                                            options={optionGroup1}
                                                            className="select2-selection"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <label htmlFor="floatingnameInput">Date and time</label>
                                                        <input
                                                            className="form-control"
                                                            type="datetime-local"
                                                            defaultValue="2022-12-02T13:45:00"
                                                            id="example-datetime-local-input"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <label htmlFor="floatingnameInput1">Description</label>
                                                <textarea className="form-control" id="floatingnameInput1" rows={3} placeholder="Writer here..." style={{ height: '205px' }}></textarea>
                                            </div>
                                        </Col>

                                        <Col md={6} className="mb-4">
                                            <label htmlFor="floatingnameInput">Event Logo</label>
                                            <input type="file" className="form-control" id="floatingnameInput" placeholder="Writer here..." />
                                        </Col>
                                        <Col md={6} className="mb-4">
                                            <label htmlFor="floatingnameInput">Event Banner</label>
                                            <input type="file" className="form-control" id="floatingnameInput" placeholder="Writer here..." />
                                        </Col>
                                    </Row>

                                    <div>
                                        <button type="button" className="btn btn-primary w-md mt-2">Submit</button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div >
        </React.Fragment >
    );
};


ShowNFT.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ShowNFT);
