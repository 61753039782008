import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//Import Images
import avatar1 from "../../assets/images/flexImages/logo.png"
import backgroundImage from '../../assets/images/background-user.png';
import { baseUrl } from "config";
import axios from "axios";

function CardUser(props) {
  const [settingsMenu, setSettingsMenu] = useState(false)
  //Setting Menu

  let token = JSON.parse(localStorage.getItem("authUser"));

  const [dashboardStats, setDashboardStats] = useState(null);

  useEffect(()=>{
    
    // Stats Show
    axios.get(baseUrl + `admin/admin-dashboard-analytics`, {
      headers: {
        'Authorization': `${token.accessToken}`
      }
    }).then((res) => {
      console.log(res?.data?.data, 'dashboard user')
      setDashboardStats(res?.data?.data)

    })
      .catch((err) => {
        console.log(err, 'dashboard err')
      })


  },[])

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card className="mb-2">
            <CardBody className="border">
              <Row className="background-user-image">
                <Col lg="3" className="p-0 pt-3 ps-3 m-0">
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={avatar1}
                        alt=""
                        className="avatar-md rounded img-thumbnail p-3"
                        style={{ backgroundColor: 'white',height: '4.5rem', width: '4.2rem' }}
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5 className="mb-1">Welcome <strong> Admin </strong></h5>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="9" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row style={{alignItems:'end'}}>
                      <Col xs="3">
                        <div className="py-4">
                          <Link to="/admin/users">
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2">Total Users</p>
                                <h5 className="mb-0">{dashboardStats?.totalUsers}</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div className="py-4">
                          <Link to="/admin/sub-admin">
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2"> Total cube generate</p>
                                <h5 className="mb-0">{dashboardStats?.totalSubAdmins}</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div className="py-4">
                          <Link to="admin/transactions">
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2">Total images and videos</p>
                                <h5 className="mb-0">{dashboardStats?.totalFiatTransactions}</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div className="py-4">
                          <Link to="admin/crypto/transactions">
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2">Total sports types</p>
                                <h5 className="mb-0">{dashboardStats?.totalCryptoTransactions}</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col xs="2" className="d-none">
                        <div className="py-4">
                          <Link to="/admin/dashboard">
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2">Transaction Amount</p>
                                <h5 className="mb-0">$0</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col xs="2" className="d-none">
                        <div className="py-4">
                          <Link to="/admin/dashboard">
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2">Total Revenue</p>
                                <h5 className="mb-0">$0</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
