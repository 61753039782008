import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import "./probability.css"
import { render } from "react-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./probability.css";
import { useFormik } from "formik";
import { fireSuccessToast, fireToast } from "../../components/Common/toast";
import axios from "axios";
import { baseUrl } from "config";
const index = () => {
  document.title = "Probability";
  let token = JSON.parse(localStorage.getItem("authUser"));
  const [data, setData] = useState({
    platinum: "",
    gold: "",
    silver: "",
    copper: "",
    bronze: "",
  });
  const [edit, setEdit] = useState(true);

  const validationSchema = Yup.object().shape({
    platinum: Yup.number()
      .min(0, "Value must be greater than or equal to 0")
      .max(100, "Value must be less than or equal to 100")
      .required("Platinum is required"),
    gold: Yup.number()
      .min(0, "Value must be greater than or equal to 0")
      .max(100, "Value must be less than or equal to 100")
      .required("Gold is required"),
    silver: Yup.number()
      .min(0, "Value must be greater than or equal to 0")
      .max(100, "Value must be less than or equal to 100")
      .required("Silver is required"),
    copper: Yup.number()
      .min(0, "Value must be greater than or equal to 0")
      .max(100, "Value must be less than or equal to 100")
      .required("Copper is required"),
      bronze: Yup.number()
      .min(0, "Value must be greater than or equal to 0")
      .max(100, "Value must be less than or equal to 100")
      .required("Bronze is required"),
    total: Yup.number().test(
      "sum",
      "The sum of all fields must be 100%",
      function (value) {
        const { platinum, gold, silver, copper, bronze } = this.parent;
        const sum = platinum + gold + silver + copper + bronze;
        return sum === 100;
      }
    ),
  });
  const err = (touched, errors) => {
    touched.platinum &&
      touched.gold &&
      touched.silver &&
      touched.copper &&
      touched.bronze &&
      errors.total &&
      fireToast("error", "Sum should be 100");
  };

  const handleSubmit = (values) => {
    console.log(values, "valuessss");
    axios
      .post(baseUrl + "/admin/rarities", values, {
        headers: {
          Authorization: `${token.accessToken}`,
        },
      })
      .then((res) => {
        fireSuccessToast("Rarities updated Successfully");
      })
      .catch((err) => {
        console.log(err, "  errr");
        fireToast("error", "Sum of rarities must be 100");
      });
  };

  const fetchData = () => {
    try {
      axios
        .get(baseUrl + `admin/rarities`, {
          headers: {
            Authorization: `${token.accessToken}`,
          },
        })
        .then((res) => {
          const { platinum, gold, silver, copper, bronze } = res.data.data;
          setData({platinum, gold, silver, copper, bronze});
          console.log(res.data.data, "test");
          // setLoadingLoder(false)
        });
    } catch (err) {
      console.log(err, "hfghg");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log(data, "datatatatt")
  const handleEdit = () => {
    setEdit(!edit);
  };
  return (
    <React.Fragment>
      <div className="page-content  rarities">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card className="border">
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0 card-title flex-grow-1">
                      {" "}
                      Probability{" "}
                    </h4>
                  </div>
                </CardBody>

                <Formik
                
                enableReinitialize
                  initialValues={data}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, handleChange, handleBlur }) => (
                    <Form>
                      <CardBody className=" py-2">
                        <Row>
                          <Col lg={6}>
                            {" "}
                            <h5 className="mb-2 mt-2 card-title flex-grow-1">
                              1. Platinum
                            </h5>
                          </Col>
                          <Col lg={2}>
                            <Field
                              id="platinum"
                              name="platinum"
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={edit}
                              // value={data.platinum}
                            />
                            <div style={{ color: "red" }}>
                              <ErrorMessage name="platinum" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardBody className=" py-2">
                        <Row>
                          <Col lg={6}>
                            {" "}
                            <h5 className="mb-2 mt-2 card-title flex-grow-1">
                              2. Gold
                            </h5>
                          </Col>
                          <Col lg={2}>
                            <Field
                              id="gold"
                              name="gold"
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={edit}
                              // value={data.gold}
                            />
                            <div style={{ color: "red" }}>
                              {" "}
                              <ErrorMessage name="gold" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardBody className="py-2">
                        <Row>
                          <Col lg={6}>
                            {" "}
                            <h5 className="mb-2 mt-2 card-title flex-grow-1">
                              3. Silver
                            </h5>
                          </Col>
                          <Col lg={2}>
                            <Field
                              id="silver"
                              name="silver"
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={edit}
                              // value={data.silver}
                            />
                            <div style={{ color: "red" }}>
                              {" "}
                              <ErrorMessage name="silver" />{" "}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardBody className=" py-2">
                        <Row>
                          <Col lg={6}>
                            {" "}
                            <h5 className="mb-2 mt-2 card-title flex-grow-1">
                              4. Copper
                            </h5>
                          </Col>
                          <Col lg={2}>
                            <Field
                              id="copper"
                              name="copper"
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={edit}
                              // value={data.copper}
                            />
                            <div style={{ color: "red" }}>
                              {" "}
                              <ErrorMessage
                                style={{ color: "red" }}
                                name="copper"
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardBody className=" py-2">
                        <Row>
                          <Col lg={6}>
                            {" "}
                            <h5 className="mb-2 mt-2 card-title flex-grow-1">
                              5. Bronze
                            </h5>
                          </Col>
                          <Col lg={2}>
                            <Field
                              id="bronze"
                              name="bronze"
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={edit}
                              // value={data.bronze}
                            />
                            <div style={{ color: "red" }}>
                              {" "}
                              <ErrorMessage
                                style={{ color: "red" }}
                                name="bronze"
                              />{" "}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      {/* <div>
                        <label>Total</label>
                        <span>
                          {values.platinum +
                            values.gold +
                            values.silver +
                            values.copper +
                            values.bronze}
                        </span>
                      </div> */}
                      <Row>
                        <Col lg={6}></Col>
                        <Col lg={2}>
                          <div className="d-flex justify-content-end  py-2 px-2">
                            <div className="px-2">
                              <Button variant="secondary" onClick={handleEdit}>
                                Edit
                              </Button>
                            </div>
                            <div>
                              <Button
                                variant="secondary"
                                type="submit"
                                onClick={() => err(touched, errors)}
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default index;
