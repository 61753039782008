import React from "react"
import { Redirect } from "react-router-dom"

// // Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Notification from "pages/Notification/Notification"


// Admin Dashboard
import AdminDashboard from "../admin/dashboard/index"
// import AdminCommission from "../admin/commission/index"
// import AdminSocialMedia from "../admin/social/index"
// import AdminTransaction from "../admin/transactions/index"
// import AdminCryptoTransaction from "../admin/crypto/index"
// import AdminPricing from "../admin/pricing/index"
// import AdminPricingEdit from "../admin/pricing/edit"


import AdminChangePassword from "../admin/account/index"
import AdminUsers from "../admin/users/index"
import AdminRevenue from "../admin/revenue/index"

// CSM Pages
import AdminCSMpages from "../admin/csm/index"

// Public CSM Pages
import PublicCSMpages from "../admin/csm/public"

// Event Management
import AdminEventCreate from "../admin/events/create"
import AdminEventShow from "../admin/events/show"
import AdminEventDetail from "../admin/events/detail"
import Banner from "../admin/banner/index"
import Terms from "../admin/terms/index"
import Privacy from "../admin/privacy/index"
import Sports from "../admin/sports/index"
import League from "../admin/league/index"
import Rarities from "../admin/rarities/index"
import Probability from "../admin/probability/index"

// Features
import FeaturesListing from "../admin/features/index"
import FeaturesCreate from "../admin/features/create"



const authProtectedRoutes = [

  // Admin Routes
  { path: "/dashboard", component: AdminDashboard },
  { path: "/admin/dashboard", component: AdminDashboard },
  { path: "/admin/change-password", component: AdminChangePassword },
  // { path: "/admin/commission", component: AdminCommission },
  // { path: "/admin/social-media", component: AdminSocialMedia },
  // { path: "/admin/transactions", component: AdminTransaction },
  // { path: "/admin/crypto/transactions", component: AdminCryptoTransaction },
  // { path: "/admin/pricing", component: AdminPricing },
  // { path: "/admin/price/edit/:id/:type", component: AdminPricingEdit },
  { path: "/admin/banner", component: Banner },
  {path: "/admin/terms", component:Terms},
  {path: "/admin/privacy", component:Privacy},
  {path: "/admin/sports", component:Sports},
  {path:"/admin/league", component:League},
  {path:"/admin/notification", component: Notification},
  {path:"/admin/rarities", component:Rarities},
  {path:"/admin/probability", component: Probability},

  { path: "/admin/features", component: FeaturesListing },
  { path: "/admin/feature/add", component: FeaturesCreate },

  { path: "/admin/users", component: AdminUsers },


  // Events
  { path: "/admin/event/create", component: AdminEventCreate },
  { path: "/admin/event/shows", component: AdminEventShow },
  { path: "/admin/event/detail/:id", component: AdminEventDetail },

  // NFT Dashboard

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [

  // Public CSM Page
  { path: "/csm/privacy-policy", component: PublicCSMpages },
  { path: "/csm/term-condition", component: PublicCSMpages },
  { path: "/csm/:id", component: PublicCSMpages },

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

]

export { authProtectedRoutes, publicRoutes }
