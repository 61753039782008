import React, { useState ,useRef} from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Input,
    FormGroup,
    Label,
    Button,
    FormFeedback,
} from "reactstrap"
import InputMask from "react-input-mask"
import * as Yup from "yup";
import { useFormik } from "formik";
import JoditEditor from 'jodit-react';
import axios from 'axios';
import { alerShow } from '../commonFunction'

// Import Editor
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import '../../assets/scss/style.scss'
import { useEffect } from "react";
import { fireToast } from "../../components/Common/toast";
const FormElement = () => {

  const inpRow = [{ name: "", file: "" }]
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setendDate] = useState(new Date())
  const [inputFields, setinputFields] = useState(inpRow)
  const [colorHor, setcolorHor] = useState("#fffff");
  const [simple_color2, setsimple_color2] = useState(0)
  const [simple_color3, setsimple_color3] = useState(0)

  const user = localStorage.getItem("authUser")
  const parseData = JSON.parse(user)
  const token = parseData?.data?.token

  // const { apiStatus, setApiSuccess, setApiFailed, changeApiStatus } =
  //   useApiStatus()

  const startDateChange = date => {
    setstartDate(date)
  }

  const endDateChange = date => {
    setendDate(date)
  }

  // Function for Create Input Fields
  function handleAddFields() {
    const item1 = { name: "", file: "" }
    setinputFields([...inputFields, item1])
  }
  const handleHor = color => {
    setcolorHor(color.hex);
  };

  // Function for Remove Input Fields
  function handleRemoveFields(idx) {
    document.getElementById("nested" + idx).style.display = "none"
  }

  const [loader, setLoader] = useState(true)
  const [terms, setTerms] = useState([])

  const editor = useRef(null);
  const [content, setContent] = useState('');

  const onChangeHandler = async (e) => {
    e.preventDefault()
    // console.log(e.target.value, "onchange e target side ")
    const { name, value } = e.target
    setTerms({
      ...terms,
      [name]: value,
    })
  }

  useEffect(() => {
    // changeApiStatus(true)
    setLoader(false)
    fetchData()
  }, [setTerms])

  const fetchData = async () => {
    await axios
      .get('https://flexcube-apis.zip2box.com/api/companypolicy/getTerms')
      .then(res => {
        setTerms(res.data.data)
        console.log(res.data, "?>>>>>>>>>>>>>>>>>>terms msg")
        setApiSuccess()
        // changeApiStatus(false)
      })
      .catch(err => {
        // changeApiStatus(false)
        // setApiFailed(err.message)
      })
  }

  const handleDescription = (editorState) => {
    setTerms({ ...terms, ['termsContent']: editorState })
  }

  const termsUpdate = async () => {
    // console.log("Hello")
    // changeApiStatus(true)
    await axios
      .post("https://flexcube-apis.zip2box.com/api/companypolicy/savePolicy", terms, {
        headers: { Authorization: `${token}` },
      })
      .then(res => {
        console.log(res, "Update terms of use")
        // setApiSuccess()
        // changeApiStatus(false)
        fetchData()
        fireToast("success","Updated Successfully")
      })
      .catch(err => {
        // changeApiStatus(false)
        // setApiFailed(err.message)
        fireToast("error","Cannot update")
      })
    setLoader(false)
  }

    return (
        <>
            <div className="page-content">
        <Container fluid>
          <p
            style={{ color: "#2a3042", fontWeight: 500, fontSize: "17px" }}
          >Terms of Use</p>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody style={{ display: "flex", justifyContent: "center" }}>
                  <form className="outer-repeater">
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Col lg="12">
                            <Label
                              htmlFor="taskname"
                              className="col-form-label col-lg-2"
                            >
                              Title
                            </Label>
                            <InputMask
                              id="taskname"
                              name="termsTitle"
                              type="text"
                              className="form-control"
                              placeholder="Terms of Use"
                              value={terms?.termsTitle}
                              onChange={onChangeHandler}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup lcassName='mb-4' row>

                        </FormGroup>


                        <FormGroup className="mb-4" row>
                          <Col lg="12">
                            <Label className="col-form-label col-lg-2">
                              Content
                            </Label>
                            <JoditEditor
                              ref={editor}
                              value={terms?.termsContent}
                              name="termsContent"
                              id="termsContent"
                              rows='5'
                              tabIndex={1} // tabIndex of textarea
                              onChange={newContent => handleDescription(newContent)}
                            />
                          </Col>
                        </FormGroup>


                        <Row>
                          <Col lg="10">
                            <Button
                              color="success"
                              className="inner"
                              onClick={termsUpdate}
                            >
                              Update
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
        </>
    )
}

export default FormElement
