import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { FileUploader } from "react-drag-drop-files";
import "./rarities.css";
import { fireSuccessToast, fireToast } from "../../components/Common/toast";
import { useSSR } from "react-i18next";
import axios from "axios";
import { baseUrl } from "config";
import { imagebaseurl } from "config";

const index = () => {
  document.title = "Rarities";
  let token = JSON.parse(localStorage.getItem("authUser"));

  const videoTypes = ["video", "mp4", "flv", "mkv", "3gp"];
  const fileTypes = ["Accepted image formats : JPG", "PNG", "jpeg"];
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);
  const [video, setVideo] = useState(null);
  const platinum = () => {
    if (
      image1 != null &&
      image2 != null &&
      image3 != null &&
      image4 != null &&
      image5 != null &&
      video != null
    ) {
      let body = {
        Type: "Platinum",
      };
      let formData = new FormData();
      for (const key in body) {
        formData.append(key, body[key]);
      }
      formData.append("images", image1);
      formData.append("images", image2);
      formData.append("images", image3);
      formData.append("images", image4);
      formData.append("images", image5);
      formData.append("video", video);

      axios
        .post(baseUrl + `cubeImages/save`, formData, {
          headers: {
            Authorization: `${token.accessToken}`,
          },
        })
        .then((res) => {
          console.log(res, " image");
          fireSuccessToast("platinum Success");
        })
        .catch((err) => {
          console.log(err, " image errr");
          fireToast("error", "Error");
        });
    } else {
      fireToast("error", "All Images and Video is Required");
    }
  };

  const [goldimg1, setGoldimg1] = useState(null);
  const [goldimg2, setGoldimg2] = useState(null);
  const [goldimg3, setGoldimg3] = useState(null);
  const [goldimg4, setGoldimg4] = useState(null);
  const [goldimg5, setGoldimg5] = useState(null);
  const [goldvideo, setGoldvideo] = useState(null);
  const gold = () => {
    if (
      goldimg1 != null &&
      goldimg2 != null &&
      goldimg3 != null &&
      goldimg4 != null &&
      goldimg5 != null &&
      goldvideo != null
    ) {
      let body = {
        Type: "Gold",
      };
      let formData = new FormData();
      for (const key in body) {
        formData.append(key, body[key]);
      }
      formData.append("images", goldimg1);
      formData.append("images", goldimg2);
      formData.append("images", goldimg3);
      formData.append("images", goldimg4);
      formData.append("images", goldimg5);
      formData.append("video", goldvideo);

      axios
        .post(baseUrl + `cubeImages/save`, formData, {
          headers: {
            Authorization: `${token.accessToken}`,
          },
        })
        .then((res) => {
          console.log(res, " image");
          fireSuccessToast("Gold Success");
        })
        .catch((err) => {
          console.log(err, " image errr");
          fireToast("error", "Error");
        });
    } else {
      fireToast("error", "All Images and Video is Required");
    }
  };

  const [silverimg1, setSilverimg1] = useState(null);
  const [silverimg2, setSilverimg2] = useState(null);
  const [silverimg3, setSilverimg3] = useState(null);
  const [silverimg4, setSilverimg4] = useState(null);
  const [silverimg5, setSilverimg5] = useState(null);
  const [silvervideo, setSilvervideo] = useState(null);
  const silver = () => {
    if (
      silverimg1 != null &&
      silverimg2 != null &&
      silverimg3 != null &&
      silverimg4 != null &&
      silverimg5 != null &&
      silvervideo != null
    ) {
      let body = {
        Type: "Silver",
      };
      let formData = new FormData();
      for (const key in body) {
        formData.append(key, body[key]);
      }
      formData.append("images", silverimg1);
      formData.append("images", silverimg2);
      formData.append("images", silverimg3);
      formData.append("images", silverimg4);
      formData.append("images", silverimg5);
      formData.append("video", silvervideo);

      axios
        .post(baseUrl + `cubeImages/save`, formData, {
          headers: {
            Authorization: `${token.accessToken}`,
          },
        })
        .then((res) => {
          console.log(res, " image");
          fireSuccessToast("Silver Success");
        })
        .catch((err) => {
          console.log(err, " image errr");
          fireToast("error", "Error");
        });
    } else {
      fireToast("error", "All Images and Video is Required");
    }
  };

  const [bronzeimg1, setBronzeimg1] = useState(null);
  const [bronzeimg2, setBronzeimg2] = useState(null);
  const [bronzeimg3, setBronzeimg3] = useState(null);
  const [bronzeimg4, setBronzeimg4] = useState(null);
  const [bronzeimg5, setBronzeimg5] = useState(null);
  const [bronzevideo, setBronzevideo] = useState(null);
  const bronze = () => {
    if (
      bronzeimg1 != null &&
      bronzeimg2 != null &&
      bronzeimg3 != null &&
      bronzeimg4 != null &&
      bronzeimg5 != null &&
      bronzevideo != null
    ) {
      let body = {
        Type: "Copper",
      };
      let formData = new FormData();
      for (const key in body) {
        formData.append(key, body[key]);
      }
      formData.append("images", bronzeimg1);
      formData.append("images", bronzeimg2);
      formData.append("images", bronzeimg3);
      formData.append("images", bronzeimg4);
      formData.append("images", bronzeimg5);
      formData.append("video", bronzevideo);

      axios
        .post(baseUrl + `cubeImages/save`, formData, {
          headers: {
            Authorization: `${token.accessToken}`,
          },
        })
        .then((res) => {
          console.log(res, " image");
          fireSuccessToast("Silver Success");
        })
        .catch((err) => {
          console.log(err, " image errr");
          fireToast("error", "Error");
        });
    } else {
      fireToast("error", "All Images and Video is Required");
    }
  };

  const [pewterimg1, setPewterimg1] = useState(null);
  const [pewterimg2, setPewterimg2] = useState(null);
  const [pewterimg3, setPewterimg3] = useState(null);
  const [pewterimg4, setPewterimg4] = useState(null);
  const [pewterimg5, setPewterimg5] = useState(null);
  const [pewtervideo, setPewtervideo] = useState(null);
  const pewter = () => {
    if (
      pewterimg1 != null &&
      pewterimg2 != null &&
      pewterimg3 != null &&
      pewterimg4 != null &&
      pewterimg5 != null &&
      pewtervideo != null
    ) {
      let body = {
        Type: "Bronze",
      };
      let formData = new FormData();
      for (const key in body) {
        formData.append(key, body[key]);
      }
      formData.append("images", pewterimg1);
      formData.append("images", pewterimg2);
      formData.append("images", pewterimg3);
      formData.append("images", pewterimg4);
      formData.append("images", pewterimg5);
      formData.append("video", pewtervideo);
      axios
        .post(baseUrl + `cubeImages/save`, formData, {
          headers: {
            Authorization: `${token.accessToken}`,
          },
        })
        .then((res) => {
          console.log(res, " image");
          fireSuccessToast("Bronze Success");
        })
        .catch((err) => {
          console.log(err, " image errr");
          fireToast("error", "Error");
        });
    } else {
      fireToast("error", "All Images and Video is Required");
    }
  };

  const [platinumApi, setPlatinumApi] = useState(null);
  const [goldApi, setGoldApi] = useState(null);
  const [silverApi, setSilverApi] = useState(null);
  const [coperApi, serCoperApi] = useState(null);
  const [bronzeApi, setBronzeApi] = useState(null);
  const apiData = () => {
    axios
      .get(baseUrl + `cubeImages/getCubeData`, {
        headers: {
          Authorization: `${token.accessToken}`,
        },
      })
      .then((res) => {
        setPlatinumApi(
          res?.data?.data?.find((item) => item._id === "Platinum")
        );
        setGoldApi(res?.data?.data?.find((item) => item._id === "Gold"));
        setSilverApi(res?.data?.data?.find((item) => item._id === "Silver"));
        serCoperApi(res?.data?.data?.find((item) => item._id === "Copper"));
        setBronzeApi(res?.data?.data?.find((item) => item._id === "Bronze"));
      })
      .catch((err) => {
        console.log(err, "platinum error");
      });
  };

  useEffect(() => {
    apiData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content  rarities">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card className="border">
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0 card-title flex-grow-1"> Rarities </h4>
                  </div>
                </CardBody>
                <CardBody className="border-bottom py-2">
                  <h5 className="mb-2 mt-2 card-title flex-grow-1">
                    1. Platinum
                  </h5>
                  <Row>
                    {platinumApi != null ? (
                      <>
                        {platinumApi?.images.map((item) => (
                          <Col lg="4">
                            <div className="sportfilediv">
                              <img
                                src={`${imagebaseurl}${item?.image}`}
                                className="rarityImages"
                                alt="img"
                              />
                            </div>
                          </Col>
                        ))}
                        <Col lg="4">
                          <div className="sportfilediv">
                            <video width="320" height="240" controls>
                              <source
                                src={`${imagebaseurl}${platinumApi?.video?.video}`}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setImage1(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setImage2(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setImage3(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setImage4(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setImage5(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>

                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setVideo(file)}
                              name="file"
                              types={videoTypes}
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="secondary"
                              onClick={() => platinum()}
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </CardBody>
                <CardBody className="border-bottom py-2">
                  <h5 className="mb-2 mt-2 card-title flex-grow-1">2. Gold</h5>
                  <Row>
                    {goldApi != null ? (
                      <>
                        {goldApi?.images.map((item) => (
                          <Col lg="4">
                            <div className="sportfilediv">
                              <img
                                src={`${imagebaseurl}${item?.image}`}
                                className="rarityImages"
                                alt="img"
                              />
                            </div>
                          </Col>
                        ))}
                        <Col lg="4">
                          <div className="sportfilediv">
                            <video width="320" height="240" controls>
                              <source
                                src={`${imagebaseurl}${goldApi?.video?.video}`}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setImage1(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setImage2(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setImage3(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setImage4(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setImage5(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>

                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setVideo(file)}
                              name="file"
                              types={videoTypes}
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="secondary"
                              onClick={() => gold()}
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </CardBody>
                <CardBody className="border-bottom py-2">
                  <h5 className="mb-2 mt-2 card-title flex-grow-1">
                    3. Silver
                  </h5>
                  <Row>
                    {silverApi != null ? (
                      <>
                        {silverApi?.images.map((item) => (
                          <Col lg="4">
                            <div className="sportfilediv">
                              <img
                                src={`${imagebaseurl}${item?.image}`}
                                className="rarityImages"
                                alt="img"
                              />
                            </div>
                          </Col>
                        ))}
                        <Col lg="4">
                          <div className="sportfilediv">
                            <video width="320" height="240" controls>
                              <source
                                src={`${imagebaseurl}${silverApi?.video?.video}`}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setSilverimg1(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setSilverimg2(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setSilverimg3(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setSilverimg4(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setSilverimg5(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>

                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setSilvervideo(file)}
                              name="file"
                              types={videoTypes}
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="secondary"
                              onClick={() => silver()}
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </CardBody>
                <CardBody className="border-bottom py-2">
                  <h5 className="mb-2 mt-2 card-title flex-grow-1">
                    4. Copper
                  </h5>
                  <Row>
                    {coperApi != null ? (
                      <>
                        {coperApi?.images.map((item) => (
                          <Col lg="4">
                            <div className="sportfilediv">
                              <img
                                src={`${imagebaseurl}${item?.image}`}
                                className="rarityImages"
                                alt="img"
                              />
                            </div>
                          </Col>
                        ))}
                        <Col lg="4">
                          <div className="sportfilediv">
                            <video width="320" height="240" controls>
                              <source
                                src={`${imagebaseurl}${coperApi?.video?.video}`}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setBronzeimg1(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setBronzeimg2(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setBronzeimg3(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setBronzeimg4(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setBronzeimg5(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>

                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setBronzevideo(file)}
                              name="file"
                              types={videoTypes}
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="secondary"
                              onClick={() => bronze()}
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </CardBody>
                <CardBody className="border-bottom py-2">
                  <h5 className="mb-2 mt-2 card-title flex-grow-1">
                    5. Bronze
                  </h5>
                  <Row>
                    {bronzeApi != null ? (
                      <>
                        {bronzeApi?.images.map((item) => (
                          <Col lg="4">
                            <div className="sportfilediv">
                              <img
                                src={`${imagebaseurl}${item?.image}`}
                                className="rarityImages"
                                alt="img"
                              />
                            </div>
                          </Col>
                        ))}
                        <Col lg="4">
                          <div className="sportfilediv">
                            <video width="320" height="240" controls>
                              <source
                                src={`${imagebaseurl}${bronzeApi?.video?.video}`}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setPewterimg1(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setPewterimg2(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setPewterimg3(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setPewterimg4(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setPewterimg5(file)}
                              name="file"
                              types={fileTypes}
                            />
                          </div>
                        </Col>

                        <Col lg="4">
                          <div className="sportfilediv">
                            <FileUploader
                              className="fileuploader form-control"
                              handleChange={(file) => setPewtervideo(file)}
                              name="file"
                              types={videoTypes}
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="secondary"
                              onClick={() => pewter()}
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default index;
