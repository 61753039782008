import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  let auth = JSON.parse(localStorage.getItem("authUser"));
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }


  return (
    <React.Fragment>
      {auth?.role == 3 ?
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Dashboard")} </li>
              <li>
                <Link to="/admin/dashboard">
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>

              <li className="menu-title">{props.t(" Customer  Management")}</li>
              <li>
                <Link to="/admin/banner" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Banner")}</span>
                </Link>
              </li>

              {/* <li className="menu-title">{props.t("Management")}</li>
              {auth?.permissions?.includes("User Management") &&
                <li>
                  <Link to="/admin/users" >
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("User Management")}</span>
                  </Link>
                </li>
              }
              {auth?.permissions?.includes("Transactions") &&
                <li>
                  <Link to="/admin/transactions" >
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("Transactions")}</span>
                  </Link>
                </li>}
              {auth?.permissions?.includes("Crypto Transactions") &&
                <li>
                  <Link to="/admin/crypto/transactions" >
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("Crypto Transactions")}</span>
                  </Link>
                </li>}
              {auth?.permissions?.includes("Manage Commission") &&
                <li>
                  <Link to="/admin/commission" >
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("Manage Commission")}</span>
                  </Link>
                </li>}
              {auth?.permissions?.includes("Sub Admins") &&
                <li>
                  <Link to="/admin/sub-admin" >
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("Sub Admins")}</span>
                  </Link>
                </li>}
              {auth?.permissions?.includes('Plan & Features') &&
                <li>
                  <Link to="/admin/pricing" >
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("Plan and Features")}</span>
                  </Link>
                </li>}

              <li>
                <Link to="/" className="has-arrow">
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Reports")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/admin/transactions" >
                      <i className="bx bxs-spreadsheet"></i>
                      <span>{props.t("Transactions")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/crypto/transactions" >
                      <i className="bx bxs-spreadsheet"></i>
                      <span>{props.t("Crypto Transactions")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="mm-active">
                <Link to="/" className="has-arrow">
                  <i className="bx bx-cog"></i>
                  <span>{props.t("Setting")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/admin/change-password" >
                      <i className="bx bx-customize"></i>
                      <span>{props.t("Change Password")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/" >
                      <i className="bx bx-customize"></i>
                      <span>{props.t("Notifications")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/social-media" >
                      <i className="bx bx-customize"></i>
                      <span>{props.t("Social Media")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/csm-pages" >
                      <i className="bx bx-customize"></i>
                      <span>{props.t("Informational Pages")}</span>
                    </Link>
                  </li>
                </ul>
              </li> */}

            
            </ul>
            <Link className="logout_button testingClass" to="/logout" style={{ padding: '20px 0px', background: '#1919a7', color: 'white' }}>
              <i className="bx bx-power-off"></i>
              <span>{props.t("Logout")}</span>
            </Link>
          </div>
        </SimpleBar>
        :

        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Dashboard")} </li>
              <li>
                <Link to="/admin/dashboard">
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>

              <li className="menu-title">{props.t("Customer Management")}</li>
              <li>
                <Link to="/admin/users" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("All Customer")}</span>
                </Link>
              </li>

              <li className="menu-title">{props.t("Manage Sports type")}</li>
              <li>
                <Link to="/admin/sports" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Create & manage sports")}</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/league" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Manage League")}</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/rarities" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Manage Rarities")}</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/probability" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Manage Probability")}</span>
                </Link>
              </li>
              {/* <li>
                <Link to="/admin/pricing" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Plan and Features")}</span>
                </Link>
              </li> */}
              <li className="menu-title">{props.t("CMS Manager")}</li>
              <li>
                <Link to="/admin/terms" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Term of Uses")}</span>
                </Link>
              </li>
            
              <li>
                <Link to="/admin/privacy" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Privacy Policy")}</span>
                </Link>
              </li>
              {/* <li className="menu-title">{props.t("Management")}</li>
              <li>
                <Link to="/admin/users" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("User Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/kyb-approval" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("KYB Approvals")}</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/kyc-approval" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("KYC Approvals")}</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/transactions" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Transactions")}</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/crypto/transactions" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Crypto Transactions")}</span>
                </Link>
              </li>
              <li className="d-none">
                <Link to="/admin/commission" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Manage Commission")}</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/sub-admin" >
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Sub Admins")}</span>
                </Link>
              </li>

              <li className="">
                <Link to="/" className="has-arrow">
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Plan and Features")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/admin/pricing" >
                      <i className="bx bxs-spreadsheet"></i>
                      <span>{props.t("Packages")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/features" >
                      <i className="bx bxs-spreadsheet"></i>
                      <span>{props.t("Features")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="">
                <Link to="/" className="has-arrow">
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Reports")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/admin/transactions" >
                      <i className="bx bxs-spreadsheet"></i>
                      <span>{props.t("Transactions")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/crypto/transactions" >
                      <i className="bx bxs-spreadsheet"></i>
                      <span>{props.t("Crypto Transactions")}</span>
                    </Link>
                  </li>
                </ul>
              </li> 

               <li className="mm-active pb-4">
                <Link to="/" className="has-arrow">
                  <i className="bx bx-cog"></i>
                  <span>{props.t("Setting")}</span>
                </Link>
                <ul className="sub-menu">
                 
                  <li>
                    <Link to="/admin/change-password" >
                      <i className="bx bx-customize"></i>
                      <span>{props.t("Change Password")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/" >
                      <i className="bx bx-customize"></i>
                      <span>{props.t("Notifications")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/social-media" >
                      <i className="bx bx-customize"></i>
                      <span>{props.t("Social Media")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/csm-pages" >
                      <i className="bx bx-customize"></i>
                      <span>{props.t("Informational Pages")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/block-email" >
                      <i className="bx bx-customize"></i>
                      <span>{props.t("Block-Email-Domains")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/kyb-documents" >
                      <i className="bx bx-customize"></i>
                      <span>{props.t("KYB Documents")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/kyc-documents" >
                      <i className="bx bx-customize"></i>
                      <span>{props.t("KYC Documents")}</span>
                    </Link>
                  </li>

                </ul>
              </li> */}


          
            </ul>
            <Link className="logout_button testingClass" to="/logout" style={{ padding: '20px 0px', background: '#1919a7', color: 'white' }}>
              <i className="bx bx-power-off"></i>
              <span>{props.t("Logout")}</span>
            </Link>
          </div>
        </SimpleBar>
      }
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
