import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../assets/scss/style.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "flatpickr/dist/themes/material_blue.css";
import "./sports.css";
import { FileUploader } from "react-drag-drop-files";
import { withTranslation } from "react-i18next";
import { baseUrl } from "config";
import { imagebaseurl } from "config";
import axios from "axios";
import { dateformatTime } from "admin/commonFunction";
import { fireSuccessToast, fireToast } from "../../components/Common/toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import { MdDeleteForever } from "@react-icons/all-files/md/MdDeleteForever";
import { BsPencilSquare } from "@react-icons/all-files/bs/BsPencilSquare";

const Transaction = (props) => {
  let token = JSON.parse(localStorage.getItem("authUser"));
  const [api, setApi] = useState();
  const [analytic, setAnalytic] = useState();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [kycstatus, setkycstatus] = useState("");
  const Refresh = () => {
    setkycstatus("");
    setStatus("");
    setSearch("");
  };
  const [topStats, setTopStats] = useState("");
  const [topKYBStats, setTopKYBStats] = useState("");
  const [listingLoader, setLoadingLoder] = useState(false);
  let [incermentInd, setIncrementInd] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPage = 10;
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setImage();
    setDataId(null);
  };
  const [dataId, setDataId] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const handleShow = (id) => {
    setDataId(id);
    setShow(true);
    let modalData = api?.find((item) => item?._id == id);
    setModalImage(modalData?.icon);
    formik.setFieldValue("name", modalData?.name);
    setImage(modalData?.icon);
  };

  const fileTypes = [
    "Accepted image formats : JPG",
    "PNG",
    "jpeg",
    " (max. 5MB)",
  ];
  const [image, setImage] = useState(null);
  const [selectImage, setSelectImage] = useState(null);

  const handleimage = (file1) => {
    setSelectImage(file1);
    let File = URL.createObjectURL(file1);
    setImage(File);
    setDataId(null);
  };

  const editUploadedFile = (e) => {
    let File = URL.createObjectURL(e.target.files[0]);
    setImage(File);
    setSelectImage(e.target.files[0]);
  };
  const deleteUploadFile = (e) => {
    setImage();
    setSelectImage(null);
  };
  //meta title
  document.title = "Sports";
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  //delete Job
  const [refresh, setRefresh] = useState(true);
  const ConfirmDeleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(baseUrl + `sports/sports/${userId}`, {
            headers: {
              Authorization: `${token.accessToken}`,
            },
          })
          .then((res) => {
            fireSuccessToast("Deleted");
            setRefresh(!refresh);
          })
          .catch((err) => {
            fireToast("error", "Error");
          });
      }
    });
  };
  useEffect(() => {
    setLoadingLoder(true);
    axios
      .get(baseUrl + `sports/getSports`, {
        headers: {
          Authorization: `${token.accessToken}`,
        },
      })
      .then((res) => {
        console.log(res?.data?.data, "dashboard user");
        setApi(res?.data?.data);
        setTopStats(res?.data?.data.analytics);
        setLoadingLoder(false);
        let pageCount = Math.ceil(
          res.data.data.getResponse.totalItems / perPage
        );
        setTotalCount(pageCount);
      })
      .catch((err) => {
        console.log(err, "dashboard err");
      });
  }, [search, status, kycstatus, currentPage, refresh]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    // getCryptoTransactions(page, perPage, searchName)
  };
  const [data, setData] = useState({
    name: "",
  });

  const formik = useFormik({
    initialValues: {
      ...data,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Must be 2 Characters")
        .required("Name is Required"),
    }),
    onSubmit: (values) => {
      let body = {
        name: values.name,
        sportsId: dataId == null ? "" : dataId,
      };
      let formData = new FormData();
      for (const key in body) {
        formData.append(key, body[key]);
      }
      formData.append("icon", selectImage);
      axios
        .post(baseUrl + `sports/save`, formData, {
          headers: {
            Authorization: `${token.accessToken}`,
          },
        })
        .then((res) => {
          console.log(res, "sport save");
          setRefresh(!refresh);
          fireSuccessToast("success");
          handleClose();
        })
        .catch((err) => {
          console.log(err, "sport save errr");
          fireToast("error", "Error");
        });
    },
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card className="border">
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0 card-title flex-grow-1"> Sports </h4>

                    <div className="search-box my-3 my-xxl-0 d-inline-block ">
                      {/* <div
                        className="position-relative"
                        style={{ marginTop: "10px", marginRight: "10px" }}
                      >
                        <label htmlFor="search-bar-0" className="search-label">
                          <span id="search-bar-0-label" className="sr-only">
                            Search this table
                          </span>
                          <input
                            id="search-bar-0"
                            type="text"
                            className="form-control"
                            placeholder={`Search `}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                      </div> */}
                    </div>

                    <div className="flex-shrink-0 ">
                      <button
                        className="btn btn-primary me-1"
                        onClick={handleShow}
                      >
                        <i className="mdi mdi-plus"></i> Create Sports
                      </button>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered font-size-13"
                  >
                    <Thead>
                      <Tr>
                        <Th>Sr. No.</Th>
                        <Th> Name</Th>
                        <Th>Created At</Th>
                        {/* <Th>Status</Th> */}
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {listingLoader ? (
                        <tr>
                          <td colSpan={6}> Loading... </td>
                        </tr>
                      ) : (
                        api?.map((item, id) => {
                          currentPage > 1
                            ? (incermentInd = (currentPage - 1) * perPage + 1)
                            : 0;

                          return (
                            <>
                              <tr key={id}>
                                <td>
                                  {" "}
                                  <span className="co-name">
                                    {" "}
                                    {incermentInd + id}{" "}
                                  </span>{" "}
                                </td>
                                {/* <td> {item?.name} </td> */}
                                <td>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={`${imagebaseurl}${item?.icon}`}
                                      className="avatar-sm me-3 rounded-circle"
                                      alt="img"
                                    />
                                    <div className="">
                                      <h5 className="font-size-14 mb-1">
                                        {item?.name}{" "}
                                      </h5>
                                    </div>
                                  </div>
                                </td>
                                <td> {dateformatTime(item?.createdAt)}</td>
                                <td>
                                  <ul className="list-unstyled hstack gap-1 mb-0">
                                    <li>
                                      <div
                                        className="btn btn-sm btn-soft-primary cstm_btn_color"
                                        onClick={() => handleShow(item?._id)}
                                      >
                                        <i className="mdi mdi-pencil text-light" />
                                      </div>
                                    </li>
                                    <li>
                                      <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-danger cstm_btn_color"
                                        onClick={() => {
                                          ConfirmDeleteUser(item?._id);
                                        }}
                                      >
                                        <i
                                          className="mdi mdi-delete-outline text-light"
                                          id="deletetooltip"
                                        />
                                      </Link>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      )}
                    </Tbody>
                  </Table>

                  <Row className="mt-2">
                    <Col md={9}></Col>
                    <Col md={3}>
                      <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={(page) => handlePageClick(page)}
                        className="pagination justify-content-end"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Sport </Modal.Title>
        </Modal.Header>
        <Form
          className="needs-validation create-vendor"
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body className="sportmodalBody">
            <label className="mb-2">Sport Name</label>
            <input
              name="name"
              className="input"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder="Enter sports"
            />

            {formik.touched.name && formik.errors.name ? (
              <p className="formikerror">{formik.errors.name}</p>
            ) : null}

            <label className="mb-2 mt-4">Sport Icon</label>
            {image == null || image == "" ? (
              <div className="sportfilediv">
                <FileUploader
                  className="fileuploader form-control"
                  handleChange={handleimage}
                  name="file"
                  types={fileTypes}
                />
              </div>
            ) : (
              <div className="sportuploadImageContainer">
                <img
                  src={
                    modalImage === image
                      ? `${imagebaseurl}${modalImage}`
                      : image
                  }
                  alt="Avatar"
                  className="image"
                  style={{
                    height: "100%",
                    maxHeight: "284px",
                    marginTop: "10px",
                  }}
                />
                <div className="overlay">
                  <label htmlFor="fileUploader" className="overlayBtn">
                    <BsPencilSquare />
                  </label>
                  <span className="overlayBtn" onClick={deleteUploadFile}>
                    <MdDeleteForever />
                  </span>
                </div>
                <input
                  type="file"
                  id="fileUploader"
                  onChange={editUploadedFile}
                  hidden
                />
              </div>
            )}
            {image == null ? <p className="formikerror">Required</p> : ""}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

Transaction.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Transaction);
