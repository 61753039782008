import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle, Table, FormGroup, Input, InputGroup } from "reactstrap";

import '../../assets/scss/style.css'

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

//i18n
import { withTranslation } from "react-i18next";
import { baseUrl } from "config"
import axios from "axios"
import * as Yup from "yup"
import { useFormik } from "formik"

const img1 = 'https://www.poma.net/wp-content/uploads/2020/09/ain-dubai-giant-observation-wheel-poma-intro-1400x1197.jpg';
const img3 = 'https://www.visitdubai.com/-/media/gathercontent/poi/a/ain-dubai/fallback-image/ain-dubai-dtcm.jpg?&cw=256&ch=256';
const img4 = "https://mydubai.media/wp-content/uploads/2021/11/image-104.jpg";
const img7 = "https://www.dubaitravelplanner.com/wp-content/uploads/2021/03/Dubai-Travel-Planner-Ain-Dubai-Depositphotos.jpg";
const img9 = "https://lh5.googleusercontent.com/p/AF1QipPMgqUyrHU7q26haWc51wRH-wWxFw6kNILJ__mE=w408-h272-k-no";// "https://lepetitjournal.com/sites/default/files/2019-04/Dubai-EAU-Ain-Dubai-la-plus-haute-roue-observation-ouvrira-en-2020.jpg";
const img11 = "https://preview.redd.it/kumah82ct2a61.jpg?auto=webp&s=c2aae87fdd3e4a96a375b68348e9ea95fde0245e";// "https://dubaitraveladventure.com/wp-content/uploads/2018/10/then-ain-dubai-1024x1024.jpg";

const NFTImage1 = 'https://upload.wikimedia.org/wikipedia/commons/e/e7/Ferris_Wheel_Ain_Dubai_in_Dubai_02.jpg';
const NFTImage2 = 'https://dubaieyewheel.com/wp-content/uploads/2022/01/WhatsApp-Image-2022-01-13-at-12.02.43-AM-300x300.jpeg';
const NFTImage3 = 'https://www.traveller.com.au/content/dam/images/h/2/1/0/x/2/image.related.socialLead.620x349.h210wq.png/1642123242787.jpg';
const NFTImage4 = 'https://images.prismic.io/mystique/54b948bc-e603-47cc-a2b7-48085cb1bf0e_ain+dubai+1.jpg?auto=compress%2Cformat&w=1200&h=450&q=75&crop=faces&fm=webp&rect=0,0,797,498';
const NFTImage5 = 'https://media.npr.org/assets/img/2021/10/26/gettyimages-1194524615_sq-972b7802bd5a897861640c76900c982e71cbc818.jpg';
const NFTImage6 = 'https://media.npr.org/assets/img/2021/10/26/gettyimages-1194524615_sq-972b7802bd5a897861640c76900c982e71cbc818.jpg';
const NFTImage7 = 'https://cdn.pixabay.com/photo/2020/12/13/23/18/ferris-wheel-5829622_1280.jpg';

import { dateformatTime } from "admin/commonFunction";

const ShowNFT = props => {

    //meta title
    document.title = "NFT's Show";
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [dateFilter, setDateFilter] = useState([]);
    let token = JSON.parse(localStorage.getItem("authUser"))

    //delete Job
    const ConfirmDeleteUser = (userId) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteFeature(userId)
            }
        })
    }

    const deleteFeature = (id) => {
        
        axios
            .delete(baseUrl + `package/feature/${id}`, {
                headers: {
                    Authorization: `${token.accessToken}`,
                },
            })
            .then(res => {
                getListingFeature()

            })
            .catch(err => {
                console.log(err, "errrrrrrrr")
            })
    }


    const [listingData, setListingData] = useState([])
    const getListingFeature = () => {
        
        axios
            .get(baseUrl + `package/features-list`, {
                headers: {
                    Authorization: `${token.accessToken}`,
                },
            })
            .then(res => {
                console.log(res.data.data, "feature listing resssssssssss")
                setListingData(res.data.data)

            })
            .catch(err => {
                console.log(err, "errrrrrrrr")
            })
    }
    useEffect(() => {
        getListingFeature();
    }, [])


    return (
        <React.Fragment>

            <div className="page-content margin-custom">
                <Container fluid>

                    <Row>
                        <Col xl="12">
                            <Card className="border">
                                <CardBody className="border-bottom py-2">
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-0 card-title flex-grow-1"> Features </h4>

                                        <div className="flex-shrink-0">
                                            <Link to="/admin/feature/add" className="btn btn-secondary text-light my-1 me-1"><i className="mdi mdi-plus"></i> Add Feature </Link>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>Feature</th>
                                                    <th>Date</th>
                                                    <th className=""> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (listingData && listingData.length > 0) ?

                                                        listingData.map((value, index) => {
                                                            return <tr key={index}>
                                                                <td>
                                                                    <div className="">
                                                                        <h5 className="font-size-14 text-muted mb-0">
                                                                            {value.name}
                                                                        </h5>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="">
                                                                        <h5 className="font-size-14 text-muted mb-0">
                                                                            {dateformatTime(value.createdAt)}
                                                                        </h5>
                                                                    </div>
                                                                </td>
                                                                <td className="">
                                                                    <Link
                                                                        to="#"
                                                                        className="btn btn-sm btn-soft-danger cstm_btn_color"
                                                                        onClick={() => {
                                                                            ConfirmDeleteUser(value._id)
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="mdi mdi-delete-outline"
                                                                            id="deletetooltip"
                                                                        />
                                                                    </Link>
                                                                </td>
                                                            </tr>

                                                        })
                                                        : <tr>
                                                            <td colSpan={3}>
                                                                No Record Found
                                                            </td>
                                                        </tr>
                                                }

                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    );
};


ShowNFT.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ShowNFT);
