import React, { useEffect, useState } from "react"
import { BsPencilSquare } from "@react-icons/all-files/bs/BsPencilSquare"
import { MdDeleteForever } from "@react-icons/all-files/md/MdDeleteForever"
import { baseUrl } from "../../../src/config"
import Loader from '../../components/Loader'

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Form,
} from "reactstrap"
import axios from "axios"
import * as Yup from "yup"
import { useFormik } from "formik"

import "./banner.css"

//i18n
import { FileUploader } from "react-drag-drop-files"


const Banner = props => {
  const [loader,setLoader]=useState(false)
  let auth = JSON.parse(localStorage.getItem("authUser"))

  const [data, setData] = useState({
    heading: "",
    subHeading: "",
    btnText: "",
    subHeadingColor: "",
    headingColor: "",
  })
  const fileTypes = [
    "Accepted image formats : JPG",
    "PNG",
    "jpeg",
    " (max. 5MB)",
  ]
  const [image, setImage] = useState()
  

  const handleimage = (file) => {
    console.log(file)
    let File = URL.createObjectURL(file);
    setImage(File)
  }

  const editUploadedFile = e => {
    let File = URL.createObjectURL(e.target.files[0])
    setImage(File)
  }

  const formik = useFormik({
    initialValues: {
      ...data,
    },
    validationSchema: Yup.object({
      heading: Yup.string()
        .min(2, "Must be 2 Characters")
        .required("Heading is Required"),
      subHeading: Yup.string()
        .min(2, "Must be 2 Characters")
        .required("subHeading is Required"),
      btnText: Yup.string().required("Button Text is required"),
      subHeadingColor: Yup.string().required(" required"),
      headingColor: Yup.string().required(" required"),
    }),
    onSubmit: values => {
      // console.log("test", values);
      setLoader(true)
      // console.log(auth.accessToken, "oooooooooooooooooooooooooo")
      axios
        .post(baseUrl + `cms/banner`, values, {
          headers: { Authorization: `${auth.accessToken}` },
        })
        .then(res => {
          setLoader(false)
          console.log(res, "resssssss")
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your Data has been saved ",
            showConfirmButton: false,
            timer: 2000,
          })
          console.log("test", values)
        })
        .catch(err => {
          setLoader(false)
          console.log("err", err)
          Swal.fire({
            position: "center",
            icon: "error",
            title: err?.response?.data?.error ?? "APIs is not in working",
            showConfirmButton: false,
            timer: 2000,
          })
        })
    },
  })
  useEffect(() => {
    axios
      .get("https://blyncc-apis.block-brew.com/api/cms/homepagecontent")
      .then(res => {
        console.log(res?.data?.data?.banner, "yyyyyyyyyyy")
        setData(res?.data?.data?.banner);
         let banner = res?.data?.data?.banner
        for(let key of Object.keys(banner)){
          formik.setFieldValue(key,banner[key]);
        }
      })
      .catch(error => {
        console.log(error, "error")
      })
  }, [])

 
  return (
    <React.Fragment>
      {
        loader?
        <Loader/>:
        ""
      }
        
      <div className="page-content margin-custom banner">
        <Container fluid>
          <Form
            className="needs-validation create-vendor"
            onSubmit={formik.handleSubmit}
          >
            <Row>
              <h3 className="my-3">Welcome to Banner </h3>
              <Col md="6">
                <Card>
                  <CardBody>
                    <label>Heading</label>
                    <br />

                    <div className="input-line-div">
                      <input
                        className="input"
                        type="text"
                        name="heading"
                        placeholder="Heading"
                        value={formik.values.heading}
                        onChange={formik.handleChange}
                      />
                      <div className="d-flex align-item-center">
                        <input
                          type="color"
                          id="favcolor"
                          name="headingColor"
                          className="color_picker form-control"
                          value={formik.values.headingColor}
                          onChange={formik.handleChange}
                        />
                        <br />
                        {formik.touched.headingColor &&
                        formik.errors.headingColor ? (
                          <p className="error">{formik.errors.headingColor}</p>
                        ) : null}
                      </div>
                    </div>
                    {formik.touched.heading && formik.errors.heading ? (
                      <p className="error">{formik.errors.heading}</p>
                    ) : null}
                    <label>Sub Heading</label>
                    <br />
                    <div className="input-line-div">
                      <textarea
                        className="input"
                        type="text"
                        name="subHeading"
                        placeholder="Sub Heading"
                        value={formik.values.subHeading}
                        onChange={formik.handleChange}
                      />
                      <div className="d-flex align-items-center">
                        <input
                          type="color"
                          className="form-control color_picker"
                          id="favcolor"
                          name="subHeadingColor"
                          value={formik.values.subHeadingColor}
                          onChange={formik.handleChange}
                        />
                        <br />
                        {formik.touched.subHeadingColor &&
                        formik.errors.subHeadingColor ? (
                          <p className="error">
                            {formik.errors.subHeadingColor}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    {formik.touched.subHeading && formik.errors.subHeading ? (
                      <p className="error">{formik.errors.subHeading}</p>
                    ) : null}
                    <label>Button Text:</label>
                    <br />
                    <div>
                      <div className="input-line-div">
                        <input
                          className="input"
                          type="text"
                          name="btnText"
                          placeholder="button text"
                          value={formik.values.btnText}
                          onChange={formik.handleChange}
                        />
                      </div>
                      {formik.touched.btnText && formik.errors.btnText ? (
                        <p className="error">{formik.errors.btnText}</p>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card>
                  <CardBody>
                    <label className="mb-3">Background Image</label>
                    <br />
                    {image == null || image == "" ? (
                      <FileUploader
                        className="fileuploader form-control"
                        handleChange={handleimage}
                        name="file"
                        types={fileTypes}
                      />
                    ) : (
                      <div className="uploadImageContainer">
                        <img src={image} alt="Avatar" className="image" style={{height:"100%", maxHeight:"284px"}}/>
                        <div className="overlay">
                          <label htmlFor="fileUploader" className="overlayBtn">
                            <BsPencilSquare />
                          </label>
                          <span
                            className="overlayBtn"
                            //   onClick={deleteUploadFile}
                          >
                            <MdDeleteForever />
                          </span>
                        </div>
                        <input
                          type="file"
                          id="fileUploader"
                          onChange={editUploadedFile}
                          hidden
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>

              <Col>
                <div className="btn-div">
                  <button className="submitbtn" type="submit">
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

// Transaction.propTypes = {
//     t: PropTypes.any,
//     chartsData: PropTypes.any,
//     onGetChartsData: PropTypes.func,
// };

export default Banner
