import React, {useState, useEffect}from "react";
import { Tbody, Th, Thead, Tr } from "react-super-responsive-table";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { baseUrl } from "config";
import moment from 'moment'
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination"
import axios from "axios";
const Notification = () => {
  document.title = "Notofication";
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState();
  let token = JSON.parse(localStorage.getItem("authUser"));
  const [listingLoader, setLoadingLoder] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPage = 20;
  const dateformatAgo = (date) => {
    return moment(date).endOf('day').fromNow()
}
  const fetchData = () => {
    try {
      axios
        .get(baseUrl + `admin/notifications?pageNumber=${currentPage}&pageSize=${perPage}`, {
          headers: {
            Authorization: `${token.accessToken}`,
          },
        })
        .then((res) => {
          console.log(res?.data?.data, "test")
          let pageCount = Math.ceil(res?.data?.data?.totalItems / perPage)
          setTotalCount(pageCount)
          setData(res?.data?.data);
          setLoadingLoder(false)
        });
    } catch (err) {
      console.log(err,'hfghg');
    }
  };


  const handlePageClick = (page) => {
    setCurrentPage(page);
    // getCryptoTransactions(page, perPage, searchName)
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);
  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <p style={{ color: "#2a3042", fontWeight: 500, fontSize: "17px" }}>
            Notification
          </p>
          <Row>
            <Col lg="12">
              <CardBody>
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered font-size-13"
                >
                  <Thead>
                    <Tr>
                    <Th> Sr. No.</Th>
                      <Th> Notification</Th>
                      <Th> Date </Th>
                      {/* <Th> Action </Th> */}
                    </Tr>
                  </Thead>
                  {/* <Tbody>
           
                      <tr>
                      <td>1.</td>

                        <td>Diva Just Joined</td>
                        <td>3 May, 2023, 10:29 PM</td>
                      </tr>
                      <tr>
                      <td>2.</td>

                        <td>John Just Joined</td>
                        <td>2 May, 2023, 4:29 PM</td>
                      </tr>
                    </Tbody> */}
                  <Tbody>
                    {listingLoader ? (
                      <tr>
                        <td colSpan={6}> Loading... </td>
                      </tr>
                    ) : (
                      data?.items.map((item, id) => {
                        currentPage > 1
                          ? (incermentInd = (currentPage - 1) * perPage + 1)
                          : 0;
                        return (
                          <>
                            
                      <tr>
                        <td>{id+1}.</td>
                        <td>{item?.text}</td>
                        <td>{dateformatAgo(item.createdAt)}</td>
                      </tr>
                     
                 
                          </>
                        );
                      })
                    )}
                  </Tbody>
                </Table>

                <Row className="mt-2">
                  <Col md={9}></Col>
                  <Col md={3}>
                    <Pagination
                      {...bootstrap5PaginationPreset}
                      current={currentPage}
                      total={totalCount}
                      onPageChange={(page) => handlePageClick(page)}
                      className="pagination justify-content-end"
                    />
                  </Col>
                </Row>
              </CardBody>

              {/* <CardBody style={{ padding: 16 }}>
                <div className="table-responsive">
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th> Notification</Th>
                        <Th> Date </Th>
                       
                      </Tr>
                    </Thead>
                    <Tbody>
                      <tr>
                        <td>Diva Just Joined</td>
                        <td>3 May, 2023, 10:29 PM</td>
                      </tr>
                      <tr>
                        <td>John Just Joined</td>
                        <td>2 May, 2023, 4:29 PM</td>
                      </tr>
                    </Tbody>
                  </Table>
                </div>
              </CardBody> */}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Notification;
