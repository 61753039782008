import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"
import { useHistory } from 'react-router'

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { Formik, useFormik } from "formik"

// action
import { fireToast } from "components/Common/toast";

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/flexImages/logo.png"
import axios from "axios"
import { baseUrl } from "../../config";
const ForgetPasswordPage = props => {
  const history = useHistory();
  const [detail, setDetail] = useState(true)
  const [email, setEmail] = useState("")

  //meta title
  document.title = "Forget Password"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("Please Enter Your OTP"),
      newPassword: Yup.string().required('Please Enter your password'),
      // .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
      //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number "
      // ),
    }),
    onSubmit: ({otp,newPassword}) => {
      console.log(newPassword);
      axios.post(baseUrl +"admin/resetPassword", {
        email: email,
        otp:`${otp}`,
        newPassword:newPassword,
      })
      .then(res => {
        fireToast("success", 'Successfully Created');
        console.log(res, "res")
        history.push('/')
      })
      .catch(err => {
        console.log(err)
        Swal.fire({
          position: "center",
          icon: "error",
          title: err?.response?.data?.error ?? "APIs is not in working",
          showConfirmButton: false,
          timer: 2000,
        });
      })
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  const Forgot = () => {
    axios
      .post(baseUrl + "admin/forgotPassword", {
        email: email,
      })
      .then(res => {
        setDetail(false)
        console.log(res, "res")
      })
      .catch(err => {
        console.log(err)
        Swal.fire({
          position: "center",
          icon: "error",
          title: err?.response?.data?.error ?? "APIs is not in working",
          showConfirmButton: false,
          timer: 2000,
        });
      })
  }
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block"></div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div  style={{backgroundColor:'#1919a7'}}>
                  <Row>
                    <Col xs={7}>
                      <div className="text-black p-4 pe-1">
                        <h5 className="text-white">Welcome Back !</h5>
                        <p className="text-white">Sign in to continue to FLEXCUBE.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div
                        className="avatar-md profile-user-wid mb-4"
                        style={{ height: "4.5rem", width: "4.5rem" }}
                      >
                        <span
                          className="avatar-title rounded"
                          style={{ background: "#ffffff" }}
                        >
                          <img src={logo} height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}
                    {detail ? (
                      <>
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={e => setEmail(e.target.value)}
                            required
                            // onChange={validation.handleChange}
                            // onBlur={validation.handleBlur}
                            // value={validation.values.email || ""}
                            // invalid={
                            //   validation.touched.email && validation.errors.email ? true : false
                            // }
                          />
                          {/* {validation.touched.email && validation.errors.email ? (
      <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
    ) : null} */}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <div
                              className="btn  w-md text-white"
                              onClick={() => Forgot()}
                              style={{background:"#1919a7"}}
                            >
                              Reset
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">OTP</Label>
                          <Input
                            name="otp"
                            className="form-control"
                            placeholder="Enter OTP"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.otp || ""}
                            invalid={
                              validation.touched.otp && validation.errors.otp
                                ? true
                                : false
                            }
                          />
                          {validation.touched.otp && validation.errors.otp ? (
                            <FormFeedback type="invalid">
                              {validation.errors.otp}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">New Password</Label>
                          <Input
                            name="newPassword"
                            className="form-control"
                            placeholder="Enter Password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.newPassword || ""}
                            invalid={
                              validation.touched.newPassword && validation.errors.newPassword
                                ? true
                                : false
                            }
                          />
                          {validation.touched.newPassword && validation.errors.newPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.newPassword}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-danger w-md "
                              type="submit"
                            >
                              Confirm
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-light">
                  Go back to{" "}
                  <Link to="login">
                    <strong className="text-primary">Login</strong>
                  </Link>{" "}
                </p>
                <p className="text-white">
                  © {new Date().getFullYear()} . Design & Develop by Block Tech
                  Brew
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
