import React from 'react'

const index = () => {
  return (
    <>
        <div id="preloader" style={{backgroundColor:'#ffffffa8'}}>
              <div id="status">
                <div className="spinner-chase">
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                </div>
              </div>
            </div>
    </>
  )
}

export default index