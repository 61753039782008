import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  Container,
  Badge,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../assets/scss/style.css";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "flatpickr/dist/themes/material_blue.css";
import { FileUploader } from "react-drag-drop-files";
import "./league.css";

import { withTranslation } from "react-i18next";

import { baseUrl } from "config";
import { imagebaseurl } from "config";
import axios from "axios";
import { dateformatTime } from "admin/commonFunction";
import { fireSuccessToast, fireToast } from "../../components/Common/toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import { MdDeleteForever } from "@react-icons/all-files/md/MdDeleteForever";
import { BsPencilSquare } from "@react-icons/all-files/bs/BsPencilSquare";

const index = () => {
  document.title = "FlexCube";

  let token = JSON.parse(localStorage.getItem("authUser"));
  const [api, setApi] = useState();
  const [analytic, setAnalytic] = useState();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [kycstatus, setkycstatus] = useState("");
  const [items, setItems] = useState([{
    teamName: "",
    teamIcon: "",
    testIcon: ""
  }])
  const Refresh = () => {
    setStatus("");
    setSearch("");
  };
  const [topStats, setTopStats] = useState("");
  const [topKYBStats, setTopKYBStats] = useState("");
  const [listingLoader, setLoadingLoder] = useState(false);
  let [incermentInd, setIncrementInd] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPage = 10;

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setImage();
    setDataId(null);
  };
  const [dataId, setDataId] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const handleShow = (id) => {
    setDataId(id);
    setShow(true);
    if(id){
      let modalData = api?.find((item) => item?._id == id);
      console.log(modalData, "modaldata")
      setModalImage(modalData?.leagueIcon);
      formik.setFieldValue("name", modalData?.leagueName);
      setImage(modalData?.leagueIcon);
      setItems(modalData?.leagueTeams?.map((item) => ({ testIcon: item?.teamIcon, teamIcon: item?.teamIcon, teamName: item?.teamName })))  
    }else{
      setImage(null);
      formik.setFieldValue("name","");
      setModalImage(null);
      setItems([{
        teamName: "",
        teamIcon: "",
        testIcon: ""
      }]);
    }
  };

  const fileTypes = [
    "Accepted image formats : JPG",
    "PNG",
    "jpeg",
    " (max. 5MB)",
  ];
  const [image, setImage] = useState(null);
  const [selectImage, setSelectImage] = useState(null);

  const handleimage = (file1) => {
    setSelectImage(file1);
    let File = URL.createObjectURL(file1);
    setImage(File);
    setDataId(null);
  };

  const editUploadedFile = (e) => {
    let File = URL.createObjectURL(e.target.files[0]);
    setImage(File);
    setSelectImage(e.target.files[0]);
    console.log(e.target.files[0], "filetest");
  };
  const deleteUploadFile = (e) => {
    setImage();
    setSelectImage(null);
  };

  // sub league image part
  const [image2, setImage2] = useState(null);
  const [selectImage2, setSelectImage2] = useState(null);

  const handleimage2 = (file1, i) => {
    console.log(file1.name, 'file1')
    setSelectImage2(file1);
    let File = URL.createObjectURL(file1);
    setImage2(File);
    setDataId(null);

    let formData = new FormData();
    formData.append("file", file1);

    axios.post(baseUrl + `leagues/upload`, formData, {
      headers: {
        'Authorization': `${token.accessToken}`
      }
    }).then((res) => {
      console.log(res?.data?.data?.filename, 'league image')
      setItems(old =>
        old.map((prev, index) =>
          index == i ? { ...prev, teamIcon: res?.data?.data?.filename,testIcon: res?.data?.data?.filename } : prev
        )
      )
    }).catch((err) => {
      console.log(err, 'league image errr')
    })
  };

  const editUploadedFile2 = (e, i) => {
    let File = URL.createObjectURL(e.target.files[0]);
    setImage2(File);
    setSelectImage2(e.target.files[0]);
    let formData = new FormData();
    formData.append("file",e.target.files[0]);

    axios.post(baseUrl + `leagues/upload`, formData, {
      headers: {
        'Authorization': `${token.accessToken}`
      }
    }).then((res) => {
      console.log(res?.data?.data?.filename, 'league image')
      setItems(old =>
        old.map((prev, index) =>
          index == i ? { ...prev, teamIcon: res?.data?.data?.filename, testIcon:res?.data?.data?.filename } : prev
        )
      )
    }).catch((err) => {
      console.log(err, 'league image errr')
    })
    
  };
  const deleteUploadFile2 = (e, i) => {
    setImage2();
    setSelectImage2(null);
    setItems(old =>
      old.map((prev, index) =>
        index == i ? { ...prev, teamIcon: "" } : prev
      )
    )
  };

  //meta title
  document.title = "Leagues";
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  //delete Job
  const [refresh, setRefresh] = useState(true);
  const ConfirmDeleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(baseUrl + `leagues/leagues/${userId}`, {
            headers: {
              Authorization: `${token.accessToken}`,
            },
          })
          .then((res) => {
            fireSuccessToast("Deleted");
            setRefresh(!refresh);
          })
          .catch((err) => {
            fireToast("error", "Error");
          });
      }
    });
  };
  useEffect(() => {
    setLoadingLoder(true);
    axios.get(baseUrl + `leagues/getLeagues`, {
      headers: {
        'Authorization': `${token.accessToken}`
      }
    }).then((res) => {
      console.log(res?.data?.data, 'league')
      setApi(res?.data?.data)
      setTopStats(res?.data?.data.analytics)
      setLoadingLoder(false)
      let pageCount = Math.ceil(res.data.data.getResponse.totalItems / perPage)
      setTotalCount(pageCount)
    })
      .catch((err) => {
        console.log(err, 'dashboard err')
      })
  }, [search, status, kycstatus, currentPage, refresh]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    // getCryptoTransactions(page, perPage, searchName)
  };
  const [data, setData] = useState({
    name: "",
  });

  const formik = useFormik({
    initialValues: {
      ...data,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Must be 2 Characters")
        .required("Name is Required"),
    }),
    onSubmit: (values) => {
      console.log(JSON.stringify(items), 'Items')
      let body = {
        leagueName: values.name,
        leagueTeams: JSON.stringify(items),
        leagueId: dataId == null ? "" : dataId,
      };
      let formData = new FormData();
      for (const key in body) {
        formData.append(key, body[key]);
      }
      formData.append("leagueIcon", selectImage);
      axios.post(baseUrl + `leagues/save`, formData, {
        headers: {
          'Authorization': `${token.accessToken}`
        }
      }).then((res) => {
        console.log(res, 'sport save')
        setRefresh(!refresh)
        fireSuccessToast("success")
        handleClose()
      }).catch((err) => {
        console.log(err, 'sport save errr')
        fireToast("error", "Error")
      })
    },
  });



  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card className="border">
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0 card-title flex-grow-1"> League </h4>

                    <div className="search-box my-3 my-xxl-0 d-inline-block ">
                      {/* <div
                        className="position-relative"
                        style={{ marginTop: "10px", marginRight: "10px" }}
                      >
                        <label htmlFor="search-bar-0" className="search-label">
                          <span id="search-bar-0-label" className="sr-only">
                            Search this table
                          </span>
                          <input
                            id="search-bar-0"
                            type="text"
                            className="form-control"
                            placeholder={`Search `}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                      </div> */}
                    </div>

                    <div className="flex-shrink-0 ">
                      <button
                        className="btn btn-primary me-1"
                        onClick={()=>handleShow()}
                      >
                        <i className="mdi mdi-plus"></i> Create League
                      </button>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered font-size-13"
                  >
                    <Thead>
                      <Tr>
                        <Th>Sr. No.</Th>
                        <Th> Name</Th>
                        <Th>Sub Leagues</Th>
                        <Th>Created At</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {listingLoader ? (
                        <tr>
                          <td colSpan={6}> Loading... </td>
                        </tr>
                      ) : (
                        api?.map((item, id) => {
                          currentPage > 1
                            ? (incermentInd = (currentPage - 1) * perPage + 1)
                            : 0;

                          return (
                            <>
                              <tr key={id}>
                                <td>
                                  {" "}
                                  <span className="co-name">
                                    {" "}
                                    {incermentInd + id}{" "}
                                  </span>{" "}
                                </td>
                                {/* <td> {item?.name} </td> */}
                                <td>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={`${imagebaseurl}${item?.leagueIcon}`}
                                      className="avatar-sm me-3 rounded-circle"
                                      alt="img"
                                    />
                                    <div className="">
                                      <h5 className="font-size-14 mb-1">
                                        {item?.leagueName}{" "}
                                      </h5>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <ul>
                                    {
                                      item?.leagueTeams.map((item1, i) => (
                                        <div className="d-flex align-items-center">
                                          <img
                                            src={`${imagebaseurl}${item1?.teamIcon}`}
                                            className="avatar-xsm me-3 rounded-circle"
                                            alt="img"
                                          />
                                          <div className="">
                                            <span className="font-size-14 mb-1">
                                              {item1?.teamName}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      ))
                                    }

                                  </ul>
                                </td>
                                <td> {dateformatTime(item?.createdAt)}</td>
                                {/* <td>
                                                                <div className="square-switch mytoggle">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`square-switch${id}`}
                                                                        switch="success"
                                                                        defaultChecked={(item.isBlocked) ? 0 : 1}
                                                                        value={item._id}
                                                                        onClick={handleChangeCheck}
                                                                    />

                                                                    <label
                                                                        htmlFor={`square-switch${id}`}
                                                                        data-on-label="Active"
                                                                        data-off-label="Inactive"
                                                                    />
                                                                </div>
                                                            </td> */}
                                <td>
                                  <ul className="list-unstyled hstack gap-1 mb-0">
                                    <li>
                                      <div
                                        className="btn btn-sm btn-soft-primary cstm_btn_color"
                                        onClick={() => handleShow(item?._id)}
                                      >
                                        <i className="mdi mdi-pencil text-light" />
                                      </div>
                                    </li>
                                    <li>
                                      <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-danger cstm_btn_color"
                                        onClick={() => {
                                          ConfirmDeleteUser(item?._id);
                                        }}
                                      >
                                        <i
                                          className="mdi mdi-delete-outline text-light"
                                          id="deletetooltip"
                                        />
                                      </Link>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      )}
                    </Tbody>
                  </Table>

                  <Row className="mt-2">
                    <Col md={9}></Col>
                    <Col md={3}>
                      <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={(page) => handlePageClick(page)}
                        className="pagination justify-content-end"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        className="modalHighlight"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create League </Modal.Title>
        </Modal.Header>
        <Form
          className="needs-validation create-vendor"
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body className="sportmodalBody">
            <Row>
              <Col md={8}>
                <label className="mb-2">Enter League</label>
                <input
                  name="name"
                  className="input"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  placeholder="Enter league"
                />

                {formik.touched.name && formik.errors.name ? (
                  <p className="formikerror">{formik.errors.name}</p>
                ) : null}
              </Col>
              <Col md={4}>
                <label className="mb-2 ">League Icon</label>
                {image == null || image == "" ? (
                  <div className="sportfilediv">
                    <FileUploader
                      className="fileuploader form-control"
                      handleChange={handleimage}
                      name="file"
                      types={fileTypes}
                    />
                  </div>
                ) : (
                  <div className="sportuploadImageContainer">
                    <img
                      src={
                        modalImage === image
                          ? `${imagebaseurl}${modalImage}`
                          : image
                      }
                      alt="Avatar"
                      className="image"
                      style={{
                        height: "100%",
                        maxHeight: "284px",
                        marginTop: "10px",
                      }}
                    />
                    <div className="overlay">
                      <label htmlFor="fileUploader" className="overlayBtn">
                        <BsPencilSquare />
                      </label>
                      <span className="overlayBtn" onClick={deleteUploadFile}>
                        <MdDeleteForever />
                      </span>
                    </div>
                    <input
                      type="file"
                      id="fileUploader"
                      onChange={editUploadedFile}
                      hidden
                    />
                  </div>
                )}
                {image == null ? <p className="formikerror">Required</p> : ""}
              </Col>
            </Row>
            <hr />
            {items?.map((item, i) => (

              <Row className="">
                <Col md={8}>
                  <label className="mb-2">Enter Sub League</label>
                  <input
                    name="subname"
                    className="input"
                    type="text"
                    value={item?.teamName}
                    onChange={e => {
                      setItems(old =>
                        old.map((prev, index) =>
                          index == i ? { ...prev, teamName: e.target.value } : prev
                        )
                      )
                    }}
                    placeholder="Enter sub league"
                  />

                </Col>
                <Col md={4}>
                  <label className="mb-2 "> Icon</label>
                  {item?.testIcon == null || item.testIcon == "" ? (
                    <div className="sportfilediv">
                      <FileUploader
                        className="fileuploader form-control"
                        handleChange={(file) => handleimage2(file, i)}
                        name="file"
                        types={fileTypes}
                      />
                    </div>
                  ) : (
                    <div className="sportuploadImageContainer">
                      <img
                        src={
                          `${imagebaseurl}${item?.testIcon}`
                        }
                        alt="Avatar"
                        className="image"
                        style={{
                          height: "100%",
                          maxHeight: "284px",
                          marginTop: "10px",
                        }}
                      />
                      <div className="overlay">
                        <label htmlFor={`fileUploader${i}`} className="overlayBtn">
                          <BsPencilSquare />
                        </label>
                        <span className="overlayBtn" onClick={(e) => deleteUploadFile2(e, i)}>
                          <MdDeleteForever />
                        </span>
                      </div>
                      <input
                        type="file"
                        id={`fileUploader${i}`}
                        onChange={(e) => editUploadedFile2(e, i)}
                        hidden
                      />
                    </div>
                  )}

                </Col>
                <div className="d-flex justify-content-end mb-1">
                  <Button
                    variant="primary"
                    onClick={() =>
                      items.length > 1
                        ? setItems((old) =>
                          old.filter((_, index) => index !== i)
                        )
                        : null
                    }
                  >
                    <MdDeleteForever />
                  </Button>
                </div>

              </Row>
            ))}
            <div className="d-flex justify-content-end">
              <Button variant="primary" onClick={() => setItems(old => [...old, { teamName: "", teamIcon: "" }])} >
                Add More
              </Button>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default index;
