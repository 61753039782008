import React from "react";
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const CardUser = ({ dataColors }) => {

  const apexCardUserChartColors = getChartColorsArray(dataColors);

  const series = [
    {
      name: "TEST",
      data: [2180, 4250, 8350, 7450, 8500, 9550, 10580, 13680, 14700, 16780, 17880, 19960],
    },
  ]

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: apexCardUserChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  return (
    <React.Fragment>
        <Card className="mb-2 border">
          <CardBody>
            <Row>
              {/* <h4 className="mb-0 card-title flex-grow-1"> Transactions </h4> */}
              <Col lg="1" className="pt-2" style={{ paddingLeft: '20px' }}>
                {/* <div className="mt-4">
                  <p>Total Transactions</p>
                  <h5>$ 453.01 </h5>
                  <Row className="mt-4">
                    <Col xs="12" className="mt-2 mb-3">
                      <div>
                        <p className="mb-2">Today's Transactions</p>
                        <h5>$ 126.01</h5>
                      </div>
                    </Col>
                    <Col xs="12" className="mt-2 ">
                      <div>
                        <p className="mb-2">Last 7 Days Transactions</p>
                        <h5>$ 252.03</h5>
                      </div>
                    </Col>
                  </Row>
                </div> */}
              </Col>
              <Col lg="10" sm="6">
                <div>
                  <div id="wallet-balance-chart">
                    <ReactApexChart
                      options={options}
                      series={series}
                      type="area"
                      height={305}
                      className="apex-charts"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>

        </Card>
    </React.Fragment>
  );
};

CardUser.propTypes = {
  options: PropTypes.any,
  series: PropTypes.any
};

export default CardUser;
