import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle, FormGroup, Input, InputGroup } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import '../../assets/scss/style.css'
import moment from "moment";

import "flatpickr/dist/themes/material_blue.css";

import { withTranslation } from "react-i18next";

import { baseUrl } from "config";
import axios from "axios";
import { dateformatTime } from "admin/commonFunction"
import { fireToast } from "../../components/Common/toast";

import Pagination, {
    bootstrap5PaginationPreset,
} from "react-responsive-pagination"

const Transaction = props => {
    let token = JSON.parse(localStorage.getItem("authUser"));
    const [api, setApi] = useState();
    const [analytic, setAnalytic] = useState();
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('');
    const [kycstatus, setkycstatus] = useState('');
    const Refresh = () => {
        setkycstatus('');
        setStatus('');
        setSearch('');
    }
    const [topStats, setTopStats] = useState("")
    const [topKYBStats, setTopKYBStats] = useState("")
    const [listingLoader, setLoadingLoder] = useState(false);
    let [incermentInd, setIncrementInd] = useState(1);

    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const perPage = 10

    //meta title
    document.title = "Users";
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    //delete Job
    const ConfirmDeleteUser = (userId) => {

        let URL = BASE_URL + 'user/delete/' + userId;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

            }
        })
    }
    useEffect(() => {

        setLoadingLoder(true)
        axios.get(baseUrl + `admin/users?pageNumber=${currentPage}&pageSize=${perPage}&search=${search}&isBlocked=${status}&KycStatus=${kycstatus}`, {
            headers: {
                'Authorization': `${token.accessToken}`
            }
        }).then((res) => {
            console.log(res?.data?.data, 'dashboard user111')
            setApi(res?.data?.data.getResponse)
            setTopStats(res?.data?.data.analytics)
            setLoadingLoder(false)
            
            let pageCount = Math.ceil(res.data.data.getResponse.totalItems / perPage)
            setTotalCount(pageCount)
        })
            .catch((err) => {
                console.log(err, 'dashboard err')
            })
    }, [search, status, kycstatus, currentPage])

    useEffect(() => {
        axios.get(baseUrl + `client/user-dashboard-analytics`, {
            headers: {
                'Authorization': `${token.accessToken}`
            }
        }).then((res) => {
            console.log(res?.data?.data, 'Analytic')
            setAnalytic(res?.data?.data)
        })
            .catch((err) => {
                console.log(err, 'Analytic err')
            })

    }, [])


    const handleChangeCheck = async event => {
        console.log(event.target.value)
        console.log(event.target.checked)

        let userID = event.target.value
        let isApproved = false
        if (!event.target.checked) {
            isApproved = true
        }

        await axios
            .post(baseUrl + `admin/isActiveClient`, { userId: userID, isBlocked: isApproved }, {
                headers: {
                    Authorization: `${token.accessToken}`,
                },
            })
            .then(function (response) {
                fireToast("success", response.data.message)
            })
            .catch(function (error) {
                fireToast("error", error.response.data.message)
            })
    }


    const handlePageClick = page => {
        setCurrentPage(page)
        // getCryptoTransactions(page, perPage, searchName)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    <Row>
                        <Col lg="12">

                            <Row>
                                <Col sm="3">
                                    <Card className="mini-stats-wid border mb-3">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="mb-2"> Total Users </p>
                                                    <h5> {topStats?.total}</h5>
                                                </div>
                                                <div className="rounded-circle bg-success align-self-center mini-stat-icon" style={{ height: '2.5rem', width: '2.6rem' }}>
                                                    <span className="avatar-title rounded-circle bg-success">
                                                        <i className="mdi mdi-account  font-size-18 text-white mb-0" />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="3">
                                    <Card className="mini-stats-wid border mb-3">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="mb-2"> Total Sports </p>
                                                    <h5> 0 </h5>
                                                </div>
                                                <div className="rounded-circle bg-info align-self-center mini-stat-icon" style={{ height: '2.5rem', width: '2.6rem' }}>
                                                    <span className="avatar-title rounded-circle bg-info">
                                                        <i className="mdi mdi-account  font-size-18 text-white mb-0" />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="3">
                                    <Card className="mini-stats-wid border mb-3">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="mb-2"> Active Users </p>
                                                    <h5> {topStats?.active} </h5>
                                                </div>
                                                <div className="rounded-circle bg-warning align-self-center mini-stat-icon" style={{ height: '2.5rem', width: '2.6rem' }}>
                                                    <span className="avatar-title rounded-circle bg-warning">
                                                        <i className="mdi mdi-account  font-size-18 text-white mb-0" />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="3">
                                    <Card className="mini-stats-wid border mb-3">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="mb-2"> Inactive Users </p>
                                                    <h5> {topStats?.inActive} </h5>
                                                </div>
                                                <div className="rounded-circle bg-primary align-self-center mini-stat-icon" style={{ height: '2.5rem', width: '2.6rem' }}>
                                                    <span className="avatar-title rounded-circle bg-primary">
                                                        <i className="mdi mdi-account  font-size-18 text-white mb-0" />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Card className="border">
                                <CardBody className="border-bottom py-2">
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-0 card-title flex-grow-1"> Users </h4>

                                        <div className="search-box my-3 my-xxl-0 d-inline-block">
                                            <div className="position-relative" style={{ marginTop: '10px' }}>
                                                <label htmlFor="search-bar-0" className="search-label">
                                                    <span id="search-bar-0-label" className="sr-only">
                                                        Search this table
                                                    </span>
                                                    <input
                                                        id="search-bar-0"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`Search Name/Email...`}
                                                        onChange={(e) => setSearch(e.target.value)}
                                                    />
                                                </label>
                                                <i className="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                        <div className="">
                                            <select onChange={(e) => setStatus(e.target.value)} className="form-control select2 mx-2 mb-xxl-0" style={{ width: '165px' }}>
                                                <option value=''>Select Status</option>
                                                <option value="0">Active</option>
                                                <option value="1">Inactive</option>
                                            </select>
                                        </div>
                                        <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block d-none">
                                            <select onChange={(e) => setkycstatus(e.target.value)} className="form-control select2 mx-2 mb-xxl-0" style={{ width: '165px' }}>
                                                <option value=''>Select KYB Status</option>
                                                <option value="PENDING">Pending</option>
                                                <option value="INPROGRESS">Inprogress</option>
                                                <option value="COMPLETED">Completed</option>
                                            </select>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div onClick={Refresh} className="btn btn-light me-1 border"><i className="mdi mdi-refresh"></i></div>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>

                                    <Table id="tech-companies-1" className="table table-striped table-bordered font-size-13">
                                        <Thead>
                                            <Tr>
                                                <Th>Sr. No.</Th>
                                                <Th>Name</Th>
                                                <Th>Email</Th>
                                                <Th>Created At</Th>
                                                <Th>Status</Th>
                                                <Th className="d-none">Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                (listingLoader) ?
                                                    <tr>
                                                        <td colSpan={6}> Loading... </td>
                                                    </tr>
                                                    :
                                                    api?.items.map((item, id) => {
                                                        (currentPage > 1) ? incermentInd = ((currentPage - 1) * perPage) + 1 : 0;
                                                        return (
                                                            <>
                                                                <tr key={id}>
                                                                    <td> <span className="co-name"> {incermentInd + (id)} </span> </td>
                                                                    {/* <td> {item?.companyName} </td> */}
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <img
                                                                                src={`https://avatars.dicebear.com/api/bottts/${id}.svg`}
                                                                                className="avatar-sm me-3 rounded-circle"
                                                                                alt="img"
                                                                            />
                                                                            <div className="">
                                                                                <h5 className="font-size-14 mb-1">{item?.name} </h5>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                    <p className="text-muted font-size-13 mb-0">{item?.email}</p>

                                                                    </td>
                                                                    <td> {dateformatTime(item?.createdAt)}</td>
                                                                    <td>
                                                                        <div className="square-switch mytoggle">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={`square-switch${id}`}
                                                                                switch="success"
                                                                                defaultChecked={(item.isBlocked) ? 0 : 1}
                                                                                value={item._id}
                                                                                onClick={handleChangeCheck}
                                                                            />
                                                                            <label
                                                                                htmlFor={`square-switch${id}`}
                                                                                data-on-label="Active"
                                                                                data-off-label="Inactive"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="d-none">
                                                                        <ul className="list-unstyled hstack gap-1 mb-0">
                                                                            <li className="d-none">
                                                                                <Link to={`/admin/dashboard`} className="btn btn-sm btn-soft-primary cstm_btn_color">
                                                                                    <i className="mdi mdi-eye text-light" />
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#" className="btn btn-sm btn-soft-danger cstm_btn_color" onClick={() => { ConfirmDeleteUser(1); }}>
                                                                                    <i className="mdi mdi-delete-outline text-light" id="deletetooltip" />
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}


                                        </Tbody>
                                    </Table>


                                    <Row className="mt-2">
                                        <Col md={9}></Col>
                                        <Col md={3}>
                                            <Pagination
                                                {...bootstrap5PaginationPreset}
                                                current={currentPage}
                                                total={totalCount}
                                                onPageChange={page => handlePageClick(page)}
                                                className="pagination justify-content-end"
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};


Transaction.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(Transaction);
