import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

// Pages Components
import CardUser from "./CardUser";
import TransactionChart from "./TransactionChart";
import CryptoChart from "./CryptoChart";
import ActivityComp from "./ActivityComp";
import SocialSource from "./SocialSource";
import TopCities from "./TopCities";
import Transactions from "./Transactions";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import '../../assets/scss/style.css'
import { baseUrl } from "config";
import axios from "axios";
import { dateformatTime } from "admin/commonFunction"
import { fireToast } from "../../components/Common/toast";

const Dashboard = props => {

  //meta title
  document.title = "Dashboard";
  let token = JSON.parse(localStorage.getItem("authUser"));


  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          /> */}

          <Row>
            <Col xl="12">
              <CardUser dataColors='["--bs-primary", "--bs-warning"]' />
            </Col>

            {/* <Col xl="12">
              <NFTUser />
            </Col> */}

            <Col xl="8" className="pe-0">
              <TransactionChart dataColors='["--bs-warning", "--bs-warning"]' />
            </Col>
            <Col xl="4">
              <CryptoChart dataColors='["--bs-warning", "--bs-info", "--bs-danger", "--bs-primary"]' />
            </Col>
            <Col xl="4" className="pe-0">
              {/* <SocialSource /> */}
            </Col>
            <Col xl="8">
              {/* <Transactions /> */}
            </Col>

          </Row>

        </Container>
      </div>

    </React.Fragment>
  );
};


Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
