import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle, FormGroup, Input, InputGroup } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import '../../assets/scss/style.css'

import "flatpickr/dist/themes/material_blue.css";

import { withTranslation } from "react-i18next";

import event2 from "../../assets/images/project/Event/Event-2.png";
import event3 from "../../assets/images/project/Event/Event-3.jpg";

const EventsShow = props => {

    //meta title
    document.title = "Events";
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom py-2">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1"> Events </h5>

                                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                            <div className="position-relative" style={{ marginTop: '10px' }}>
                                                <label htmlFor="search-bar-0" className="search-label">
                                                    <span id="search-bar-0-label" className="sr-only">
                                                        Search this table
                                                    </span>
                                                    <input
                                                        id="search-bar-0"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`Search...`}
                                                    />
                                                </label>
                                                <i className="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Link to="#!" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <Row>
                                        <Col xl={3}>
                                            <Link to="/admin/event/detail/1">
                                                <Card>
                                                    <img
                                                        className="card-img-top img-fluid rounded"
                                                        src="https://scontent.fixc2-1.fna.fbcdn.net/v/t39.30808-6/309605797_2590153581121422_9041116312301452710_n.jpg?stp=dst-jpg_p180x540&_nc_cat=108&ccb=1-7&_nc_sid=340051&_nc_ohc=L6b0jzo4M9IAX8DbK5p&_nc_ht=scontent.fixc2-1.fna&oh=00_AfA2OcaJKwj0fHnrytK6jwCdBuORN9DD-1d5Pn2IoyCgaA&oe=639332D9"
                                                    />
                                                    <div className="p-3">
                                                        <b> Ladies Island Nights </b> <br />
                                                        Bluewaters Island, Dubai <strong className="ps-1"> 02 Nov, 2022 </strong>
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>

                                        <Col xl={3}>
                                            <Link to="/admin/event/detail/1">
                                                <Card>
                                                    <img
                                                        className="card-img-top img-fluid rounded"
                                                        src="https://bluewatersdubai.ae/-/media/Project/MeraasEcosystem/Bluewater/Whats-On/droneshow/droneshow.jpg"
                                                    />
                                                    <div className="p-3">
                                                        <b> DSF Drone Show </b> <br />
                                                        Bluewaters Island, Dubai <strong className="ps-1"> 08 Nov, 2022 </strong>
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>

                                        <Col xl={3}>
                                            <Link to="/admin/event/detail/1">
                                                <Card>
                                                    <img
                                                        className="card-img-top img-fluid rounded"
                                                        src="https://scontent.fixc2-1.fna.fbcdn.net/v/t39.30808-6/289237906_2505430552927059_765791128219403944_n.jpg?stp=dst-jpg_p180x540&_nc_cat=107&ccb=1-7&_nc_sid=340051&_nc_ohc=s16dG8eBZ3EAX8CKqr3&_nc_ht=scontent.fixc2-1.fna&oh=00_AfAUgMILhqqKA1lAZKqsw83xx6vWm9V8M06qAM-sjT7wdA&oe=6394EB3D"
                                                    />
                                                    <div className="p-3">
                                                        <b> Island Brunch </b> <br />
                                                        Bluewaters Island, Dubai <strong className="ps-1"> 09 Nov, 2022 </strong>
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>

                                        <Col xl={3}>
                                            <Link to="/admin/event/detail/1">
                                                <Card>
                                                    <img
                                                        className="card-img-top img-fluid rounded"
                                                        src="https://scontent.fixc2-1.fna.fbcdn.net/v/t39.30808-6/310860626_2604889412981172_3212638291320219653_n.jpg?stp=dst-jpg_p180x540&_nc_cat=108&ccb=1-7&_nc_sid=340051&_nc_ohc=MT-coUQM2jMAX8vZBjP&_nc_ht=scontent.fixc2-1.fna&oh=00_AfC3xXpQGcQYk9xxBVG3pe9QZuPZkBFh71X2Ee09nHXKlA&oe=6393CE66"
                                                    />
                                                    <div className="p-3">
                                                        <b> Bluewaters Music Street Festival! </b> <br />
                                                        Bluewaters Island, Dubai <strong className="ps-1"> 12 Nov, 2022 </strong>
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>

                                        <Col xl={3}>
                                            <Link to="/admin/event/detail/1">
                                                <Card>
                                                    <img
                                                        className="card-img-top img-fluid rounded"
                                                        src="https://scontent.fixc2-1.fna.fbcdn.net/v/t39.30808-6/316949517_2646098495526930_7258497492226279438_n.jpg?stp=dst-jpg_s960x960&_nc_cat=108&ccb=1-7&_nc_sid=340051&_nc_ohc=c8IyW6HsxmsAX_LLNvn&_nc_oc=AQmtg2niI89INa3vUj0B7tppDfifOMvPOmdCMGhSza6tmDEIEtGz8A0OaKLwXJzW35Y&_nc_ht=scontent.fixc2-1.fna&oh=00_AfAyQeuVqiHrhs3c0kfir21vFqGxRxl23JG8IWaIFnJRkg&oe=6394D67A"
                                                    />
                                                    <div className="p-3">
                                                        <b> Santa’s Secret Island  </b> <br />
                                                        Bluewaters Island, Dubai <strong className="ps-1"> 18 Nov, 2022 </strong>
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>

                                        <Col xl={3}>
                                            <Link to="/admin/event/detail/1">
                                                <Card>
                                                    <img
                                                        className="card-img-top img-fluid rounded"
                                                        src="https://scontent.fixc2-2.fna.fbcdn.net/v/t39.30808-6/291877394_2517007558436025_6808858050546515437_n.jpg?stp=dst-jpg_s960x960&_nc_cat=109&ccb=1-7&_nc_sid=340051&_nc_ohc=R4-YzXar1fEAX9oMDoH&_nc_ht=scontent.fixc2-2.fna&oh=00_AfB8w4eTmrwtPpH04k_1zMNIxvTmY1FYltE5-aNzYlpBdA&oe=63943662"
                                                    />
                                                    <div className="p-3">
                                                        <b> Eid At Bluewaters </b> <br />
                                                        Bluewaters Island, Dubai <strong className="ps-1"> 20 Nov, 2022 </strong>
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>


                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};


EventsShow.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(EventsShow);
