import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import { baseUrl } from "config";
import axios from "axios";

const WalletBalance = ({ dataColors }) => {

    const radialChartColors = getChartColorsArray(dataColors);
    let token = JSON.parse(localStorage.getItem("authUser"));

    // const series = [77, 70, 57]
    const [series, setSeries] = useState([70, 57])
    const [options, setOptions] = useState({
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        fontSize: "22px",
                    },
                    value: {
                        fontSize: "16px",
                    },
                    total: {
                        show: true,
                        label: "",
                        formatter: function (w) {
                            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                            return ''
                        },
                    },
                },
            },
        },
        labels: [ "Cube", "Others"],
        colors: radialChartColors,
    })


    const [cryptoTrans, setCryptoTrans] = useState([]);
    useEffect(() => {

        // Crypto Transaction Graph
        // axios.get(baseUrl + `transaction/crypto-transactions-analytics`, {
        //     headers: {
        //         'Authorization': `${token.accessToken}`
        //     }
        // }).then((res) => {
        //     console.log(res?.data?.data, 'crypto graph user')
        //     setCryptoTrans(res?.data?.data?.cryptoTransactions)

        //     var totalSum = 0;
        //     (res?.data?.data?.cryptoTransactions) && res?.data?.data?.cryptoTransactions.map((value, index) => {
        //         totalSum += parseFloat(value.amount.$numberDecimal);
        //     })

        //     var statsLabel = [];
        //     var statsNumber = [];
        //     (res?.data?.data?.cryptoTransactions) && res?.data?.data?.cryptoTransactions.map((value, index) => {
        //         statsLabel.push((value.currency == 'native') ? 'XLM' : value.currency.toUpperCase())
        //         statsNumber.push((parseFloat(value.amount.$numberDecimal) * 100 / totalSum).toFixed(2))
        //     })

        //     setSeries(statsNumber)
        //     // options.labels = statsLabel;
        //     setOptions({...options, ['labels']: statsLabel})
        // })
        //     .catch((err) => {
        //         console.log(err, 'crypto graph err')
        //     })
    }, [])

    return (
        <React.Fragment>
            <Card className="mb-2 border">
                <CardBody>
                    {/* <div className="float-end">
                        <select defaultValue="MA" className="form-select form-select-sm ms-2">
                            <option value="MA">March</option>
                            <option value="FE">February</option>
                            <option value="JA">January</option>
                            <option value="DE">December</option>
                        </select>
                    </div> */}
                    <h4 className="card-title mb-3"> Total Cube generated </h4>

                    <Row>

                        <Col lg="3" className="align-self-center">
                            {
                                cryptoTrans.map((value, index) => {
                                    return <div key={index} className="mt-3 pt-2">
                                        <p className="mb-2">
                                            <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary" />{" "}
                                            {/* {(value.currency == 'native') ? 'XLM' : value.currency} */}
                                        </p>
                                        <h5>
                                            {parseFloat(value.amount.$numberDecimal).toFixed(2)} 
                                        </h5>
                                    </div>
                                })
                            }

                            <div className="mt-4 pt-2">
                                <p className="mb-2">
                                    <i className="mdi mdi-circle align-middle font-size-10 me-2 text-danger" />{" "}
                                    Others
                                </p>
                                <h5>
                                    0.00 Other
                                </h5>
                            </div>
                        </Col>

                        <Col lg="9">
                            <div>
                                <div id="wallet-balance-chart">
                                    <ReactApexChart
                                        options={options}
                                        series={series}
                                        type="radialBar"
                                        height="310"
                                        className="apex-charts"
                                    />
                                </div>
                            </div>
                        </Col>

                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default WalletBalance
